import axios from "axios";
import baseConfig from "../Config";
import {loginFailed, loginSuccess} from "../store/auth/reducers";
import toast from "react-hot-toast";

export class DashboardService {
    static async expenseByCategories(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/expense-by-categories`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async averageExpenseByMonth(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/average-expense`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async dailyVehicleExpense(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/daily-expense`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async topExpensiveVehicle(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/top-expensive-vehicles`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async topExpensiveDrivers(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/top-expensive-drivers`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async getTotalExpenseVehicleByCategory(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/expensive-vehicles-by-category`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async getTotalExpenseVehicleByType(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/expensive-vehicles-by-type`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async getTotalExpenseOutletByVehicle(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/expensive-outlet-by-vehicle`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async getTotalExpenseVehicleByOutlet(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/expensive-vehicle-by-outlet`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async getTotalExpenseByCompany(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/total-expense-by-company`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async getTotalExpenseByChain(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/total-expense-by-chain`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async getTotalExpenseByOutlet(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/total-expense-by-outlet`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async getTotalVehicleExpenseByPurpose(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/total-expense-by-purpose`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async penaltyByType(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/penalty-by-type`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async penaltyByChain(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/penalty-by-chain`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async penaltyByDriver(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/penalty-by-driver`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }

    static async penaltyByVehicle(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                let res = await axios.post(`${baseConfig.apiBaseUrl}/dashboard/penalty-by-vehicle`, body, config);
                return res.data;
            } catch (err) {

            }
        }
    }
}
