const Config = {
  // apiBaseUrl: "http://localhost:4444/apis/v1/core/admin",
  // apiCoreBaseUrl: "http://localhost:4444/apis/v1/core",
  // apiBaseUrl: "http://15.207.132.8:4444/apis/v1/core/admin",
  // apiCoreBaseUrl: "http://15.207.132.8:4444/apis/v1/core"
  apiBaseUrl: "https://vms-api.al-mejhem.com/apis/v1/core/admin",
  apiCoreBaseUrl: "https://vms-api.al-mejhem.com/apis/v1/core"
};

export default Config;
