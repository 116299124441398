import React, {useEffect, useState} from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net';
import {useLocation, useNavigate} from "react-router";

import baseConfig from "../../Config";
import {VehicleService} from "../../services/VehicleService";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";


const ManageVehicles = () => {
    const admin = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();

    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/vehicles')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);


    const getItems = () => {
        let companies = [], chains = [], outlets = [];
        if (admin.permissions.find((item) => item.title !== 'Super Admin')) {
            companies = admin.companies.map((company) => company._id);
            chains = admin.chains.map((chain) => chain._id);
            outlets = admin.outlets.map((outlet) => outlet._id);
        }

        try {
            $('#items').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        let token = localStorage.getItem('token')
        $('#items').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<div class="figure"><img src="${data}" style="width: 60px;height: 60px; border-radius: 5px"></div>`;
                        }
                        return '<div></div>';
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Active') {
                            return `<p class="badge bg-success p-2">Active</p>`;
                        } else {
                            return `<p class="badge bg-danger p-2">Inactive</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 7
                },
                {

                    "render": function (data, type, row) {
                        if (row[7] === 'Active') {
                            return `<button class="btn btn-danger">Deactivate</button>`;
                        } else {
                            return `<button class="btn btn-success">Activate</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            if (!permissions.edit) {
                                toast.error('You do not have permission to perform this action');
                                return;
                            }
                            const data = {
                                status: rowData[7] === 'Active' ? 'Inactive' : 'Active'
                            }
                            const res = await VehicleService.update(rowData[10], data);
                            if (res) {
                                toast.success("Vehicle updated successfully", {});
                                getItems();
                            }
                        })
                    },
                    "targets": 9
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            if (!permissions.view) {
                                toast.error('You do not have permission to perform this action');
                                return;
                            }
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 10
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger">Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            if (!permissions.delete) {
                                toast.error('You do not have permission to perform this action');
                                return;
                            }
                            const data = {
                                deleted: true
                            }
                            const res = await VehicleService.update(rowData[11], data);
                            if (res) {
                                toast.success("Vehicle deleted successfully", {});
                                getItems();
                            }
                        })
                    },
                    "targets": 11
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/vehicle/list`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify({
                        ...d,
                        ...(companies.length > 0 ? {companies} : {}),
                        ...(chains.length > 0 ? {chains} : {}),
                        ...(outlets.length > 0 ? {outlets} : {}),
                    });
                },
            }
        });
    }

    useEffect(() => {
        if (permissions.view) {
            getItems();
        }
    }, [permissions]);

    return (
        <div>

            <div className="card mt-5">
                <div className="card-header d-flex justify-content-between p-3">
                    <h5 className="card-title">Manage Vehicles</h5>
                    <div className="card-tools">
                        {permissions.add && <button type="button" className="btn btn-secondary"
                                                    onClick={() => navigate(`${location.pathname}/add`)}>Add
                            Vehicle</button>}
                    </div>
                </div>
                <div className="card-body">
                    <div className='table-responsive w-100'>
                        <table
                            id="items"
                            className='table datatable w-100'
                        >
                            <thead>
                            <tr>
                                <th>Picture</th>
                                <th>Name</th>
                                <th>Number</th>
                                <th>Type</th>
                                <th>Company</th>
                                <th>Chain</th>
                                <th>Outlet</th>
                                <th>Status</th>
                                <th>Added Date</th>
                                <th>Change Status</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ManageVehicles;
