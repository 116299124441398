import Select from "react-select";
import {useEffect, useState} from "react";
import {ChainService} from "../../services/ChainService";
import {CompanyService} from "../../services/CompanyService";
import {OutletService} from "../../services/OutletService";
import {UploadService} from "../../services/UploadService";
import {VehicleService} from "../../services/VehicleService";
import toast from "react-hot-toast";
import {useNavigate, useParams} from "react-router";
import {useSelector} from "react-redux";

const EditVehicle = () => {
    const {id} = useParams();
    const admin = useSelector((state) => state.auth);
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [type, setType] = useState(null);
    const [purpose, setPurpose] = useState(null);
    const [ownerType, setOwnerType] = useState(null);
    const [modal, setModal] = useState(null);
    const [picture, setPicture] = useState(null);
    const [wafraExpiry, setWafraExpiry] = useState(null);
    const [pictureUrl, setPictureUrl] = useState(null);
    const [chain, setChain] = useState(null);
    const [outlet, setOutlet] = useState(null);
    const [company, setCompany] = useState(null);
    const [chainOptions, setChainOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [outletOptions, setOutletOptions] = useState([]);
    const [ownerTypeOptions, setOwnerTypeOptions] = useState([
        {value: 'Company', label: 'Company'},
        {value: 'Rental', label: 'Rental'},
        {value: 'Staff', label: 'Staff'},
    ]);
    const [typeOptions, setTypeOptions] = useState([
        {value: 'Sedan', label: 'Sedan'},
        {value: 'Hatchback', label: 'Hatchback'},
        {value: 'SUV', label: 'SUV'},
        {value: 'MPV', label: 'MPV'},
        {value: 'Convertible', label: 'Convertible'},
        {value: 'Coupe', label: 'Coupe'},
        {value: 'Truck', label: 'Truck'},
        {value: 'Van', label: 'Van'},
        {value: 'Bus', label: 'Bus'},
        {value: 'Motorcycle', label: 'Motorcycle'},
        {value: 'Scooter', label: 'Scooter'},
        {value: 'Moped', label: 'Moped'},
        {value: 'RV', label: 'RV'},
        {value: 'Campervan', label: 'Campervan'},
        {value: 'Motorhome', label: 'Motorhome'},
        {value: 'Ambulance', label: 'Ambulance'},
        {value: 'FireTruck', label: 'Fire Truck'},
        {value: 'MilitaryVehicle', label: 'Military Vehicle'},
        {value: 'Tractor', label: 'Tractor'},
        {value: 'Harvester', label: 'Harvester'},
        {value: 'Bulldozer', label: 'Bulldozer'},
        {value: 'Backhoe', label: 'Backhoe'},
        {value: 'Loader', label: 'Loader'},
        {value: 'ElectricCar', label: 'Electric Car'},
        {value: 'ElectricBike', label: 'Electric Bike'},
        {value: 'ElectricScooter', label: 'Electric Scooter'},
        {value: 'HybridCar', label: 'Hybrid Car'},
        {value: 'PlugInHybridVehicle', label: 'Plug-in Hybrid Vehicle'},
        {value: 'HydrogenFuelCellVehicle', label: 'Hydrogen Fuel Cell Vehicle'},
        {value: 'CNGVehicle', label: 'CNG Vehicle'},
        {value: 'Half Lorry', label: 'Half Lorry'},
        {value: 'HIACE -W CHILLER', label: 'HIACE -W CHILLER'},
        {value: 'URVAN', label: 'URVAN'},
        {value: 'H-1', label: 'H-1'},
    ]);
    const [loading, setLoading] = useState(false);
    const [allowedPermissions, setAllowedPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/vehicles')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setAllowedPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!allowedPermissions.edit) {
            toast.error('You are not allowed to perform this action');
            return;
        }
        setLoading(true);
        let picUrl = pictureUrl;
        if (picture !== null) {
            picUrl = await uploadFile(picture);
        }
        const data = {
            name,
            number,
            type: type.value,
            ownerType: ownerType.value,
            modal,
            purpose,
            picture: picUrl,
            chain: chain.value,
            outlet: outlet.value,
            company: company.value,
            wafraExpiry,
        };
        const res = VehicleService.update(id, data);
        if (res) {
            toast.success("Vehicle updated successfully", {});
        }
        setLoading(false);
    }

    const uploadFile = async (file) => {
        let formData = new FormData();
        formData.append("files", file);
        const urls = await UploadService.upload(formData);
        console.log(urls);
        return urls.length > 0 ? urls[0] : '';
    }

    const loadDetails = async () => {
        const item = await VehicleService.get(id);
        if (item) {
            setName(item.name);
            setNumber(item.number);
            setType({label: item.type, value: item.type});
            setOwnerType({label: item.ownerType, value: item.ownerType});
            setModal(item.modal);
            setPictureUrl(item.picture);
            setPurpose(item.purpose);
            setChain({label: item.chain.address, value: item.chain._id});
            setOutlet({label: item.outlet.location, value: item.outlet._id});
            setCompany({label: item.company.name, value: item.company._id});
            setWafraExpiry(item.wafraExpiry ? item.wafraExpiry.split('T')[0] : null);
        }
    }

    const getCompanies = async () => {
        const allItems = await CompanyService.companies();
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.companies.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].name, value: allItems[i]._id});
            }
        }
        setCompanyOptions(items);
    }

    const getChains = async () => {
        const allItems = await ChainService.chains([company.value]);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.chains.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].address, value: allItems[i]._id});
            }
        }
        setChainOptions(items);
    }

    const getOutlets = async () => {
        const allItems = await OutletService.outlets([chain.value]);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.outlets.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].location, value: allItems[i]._id});
            }
        }
        setOutletOptions(items);
    }

    useEffect(() => {
        getCompanies();
    }, []);

    useEffect(() => {
        if (company !== null) {
            getChains();
        }
    }, [company])

    useEffect(() => {
        if (chain !== null) {
            getOutlets();
        }
    }, [chain])

    useEffect(() => {
        loadDetails();
    }, [id])

    return (
        <>
            <div className="card mb-5">
                <div className="card-header p-3">
                    <h5 className="card-title">Edit Vehicle</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Name <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="name" name="location"
                                           placeholder="Vehicle Name" value={name}
                                           onChange={(e) => setName(e.target.value)} required={true}/>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Number <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="number" name="number"
                                           placeholder="Vehicle Number" value={number}
                                           onChange={(e) => setNumber(e.target.value)} required={true}/>
                                </div>
                            </div>

                            <div className="mb-3 col-sm-12 col-md-6">
                                <label htmlFor="type" className="form-label">Vehicle Type <span
                                    className='text-danger'>(required)</span></label>
                                <Select
                                    name="chain"
                                    options={typeOptions}
                                    value={type}
                                    onChange={setType} required={true}/>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Purpose</label>
                                    <input type="text" className="form-control" id="purpose" name="purpose"
                                           placeholder="Vehicle Purpose" value={purpose}
                                           onChange={(e) => setPurpose(e.target.value)}/>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Dafter Expiry</label>
                                    <input type="date" className="form-control" id="wafra-expiry" name="wafra-expiry"
                                        placeholder="Dafter Expiry" value={wafraExpiry}
                                        onChange={(e) => setWafraExpiry(e.target.value)} />
                                </div>
                            </div>

                            <div className="mb-3 col-sm-12 col-md-6">
                                <label htmlFor="type" className="form-label">Owner Type <span
                                    className='text-danger'>(required)</span></label>
                                <Select
                                    name="chain"
                                    options={ownerTypeOptions}
                                    value={ownerType}
                                    onChange={setOwnerType} required={true}/>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Model <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="model" name="model"
                                           placeholder="Vehicle Model" value={modal}
                                           onChange={(e) => setModal(e.target.value)} required={true}/>
                                </div>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-6">
                                <label htmlFor="image" className="form-label">Vehicle Picture</label>
                                <input type="file" className="form-control" id="logo" name="logo"
                                       onChange={(e) => setPicture(e.target.files[0])}/>
                                {pictureUrl !== null && <a href={pictureUrl} target={'_blank'}>Click here to download image</a>}
                            </div>

                            <div className="mb-3 col-sm-12 col-md-6">
                                <label htmlFor="type" className="form-label">Company <span
                                    className='text-danger'>(required)</span></label>
                                <Select
                                    name="chain"
                                    options={companyOptions}
                                    value={company}
                                    onChange={setCompany} required={true}/>
                            </div>

                            <div className="mb-3 col-sm-12 col-md-6">
                                <label htmlFor="type" className="form-label">Chain <span
                                    className='text-danger'>(required)</span></label>
                                <Select
                                    name="chain"
                                    options={chainOptions}
                                    value={chain}
                                    onChange={setChain} required={true}/>
                            </div>

                            <div className="mb-3 col-sm-12 col-md-6">
                                <label htmlFor="type" className="form-label">Outlet <span
                                    className='text-danger'>(required)</span></label>
                                <Select
                                    name="chain"
                                    options={outletOptions}
                                    value={outlet}
                                    onChange={setOutlet} required={true}/>
                            </div>
                        </div>

                       <div className={'d-flex'}>
                           <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                               <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                           </button>
                           <button type="button" className="btn btn-secondary me-2" disabled={loading} onClick={() => {
                                 window.history.back();
                           }}>{loading &&
                               <span className="spinner-border spinner-border-sm me-2" role="status"/>} Back
                           </button>
                       </div>

                    </form>

                </div>
            </div>
        </>
    );
}

export default EditVehicle;
