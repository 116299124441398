import Select from "react-select";
import React, {useEffect, useState} from "react";
import {DateRangePicker} from "react-date-range";
import * as moment from "moment";
import {CompanyService} from "../../services/CompanyService";
import {ChainService} from "../../services/ChainService";
import {OutletService} from "../../services/OutletService";
import {useSelector} from "react-redux";
import {EmployeeService} from "../../services/EmployeeService";
import {VehicleService} from "../../services/VehicleService";
import $ from "jquery";
import baseConfig from "../../Config";
import {PenaltyTypeService} from "../../services/PenaltyType";

const ManagePenaltyReports = () => {
    const admin = useSelector((state) => state.auth);
    const [company, setCompany] = useState(null);
    const [chain, setChain] = useState(null);
    const [outlet, setOutlet] = useState(null);
    const [type, setType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [vehicle, setVehicle] = useState(null);
    const [driver, setDriver] = useState(null);
    const [chainOptions, setChainOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [outletOptions, setOutletOptions] = useState([]);

    const [typeOptions, setTypeOptions] = useState([]);
    const [driverOptions, setDriverOptions] = useState([]);
    const [vehicleOptions, setVehicleOptions] = useState([]);
    const [selectionRange, setSelectionRange] = useState(
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
    );
    const [showFilters, setShowFilters] = useState(false);
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/reports') || permission.routes.includes('/reports/penalty')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    const getTypes = async () => {
        const items = await PenaltyTypeService.types();
        const _types = [];
        for (let i = 0; i < items.length; i++) {
            _types.push({label: items[i].title, value: items[i]._id});
        }
        setTypeOptions(_types);
    }

    const getCompanies = async () => {
        const allItems = await CompanyService.companies();
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.companies.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].name, value: allItems[i]._id});
            }
        }
        setCompanyOptions(items);
    }

    const getChains = async () => {
        const allItems = await ChainService.chains([company.value]);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.chains.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].address, value: allItems[i]._id});
            }
        }
        setChainOptions(items);
    }

    const getOutlets = async () => {
        const allItems = await OutletService.outlets([chain.value]);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.outlets.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].location, value: allItems[i]._id});
            }
        }
        setOutletOptions(items);
    }

    const getDrivers = async () => {
        const value = company ? company.value : 'all';
        const data = {
            companies: [value],
            ...(chain && {chains: [chain.value]}),
            ...(outlet && {outlets: [outlet.value]}),
        };
        const allItems = await EmployeeService.employees(data);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            items.push({label: allItems[i].name, value: allItems[i]._id});
        }
        setDriverOptions(items);
    }

    const getVehicles = async () => {
        const value = company ? company.value : 'all';
        const data = {
            companies: [value],
            ...(chain && {chains: [chain.value]}),
            ...(outlet && {outlets: [outlet.value]}),
        };
        const allItems = await VehicleService.vehicles(data);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            items.push({label: allItems[i].number, value: allItems[i]._id});
        }
        setVehicleOptions(items);
    }

    useEffect(() => {
        getCompanies();
        getTypes();
    }, []);

    useEffect(() => {
        if (company !== null) {
            getChains();
        }
    }, [company])

    useEffect(() => {
        if (chain !== null) {
            getOutlets();
        }
    }, [chain])


    useEffect(() => {
        getVehicles();
        getDrivers();
    }, [chain, company, outlet])

    const getDriverReportData = () => {
        let id = 'company-report';
        if (driver) {
            id = 'driver-report';
        }
        if (vehicle) {
            id = 'vehicle-report';
        }
        try {
            $(`#${id}`).DataTable().destroy();
        } catch (e) {
            console.log('ERROR');
            console.log(e);
        }
        let token = localStorage.getItem('token')
        $(`#${id}`).DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            retrieve: true,
            columnDefs: [
                ...((!driver && !vehicle) ?  [
                    {

                        "render": function (data, type, row) {
                            if (data) {
                                return `<div class="figure"><img src="${data}" style="width: 60px;height: 60px; border-radius: 5px"></div>`;
                            }
                            return '<div></div>';
                        },
                        "createdCell": (td, cellData, rowData, row, col) => {

                        },
                        "targets": 1
                    },
                ] : []),
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/report/penalty/list`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify({
                        ...d,
                        ...(company ? {company: company.value} : {}),
                        ...(chain ? {chain: chain.value} : {}),
                        ...(outlet ? {outlet: outlet.value} : {}),
                        ...(startDate ? {startDate: startDate} : {}),
                        ...(endDate ? {endDate: endDate} : {}),
                        ...(type ? {type: type.value} : {}),
                        ...(vehicle ? {vehicle: vehicle.value} : {}),
                        ...(driver ? {driver: driver.value} : {}),
                    });
                },
            }
        });
    }

    useEffect(() => {
        if (permissions.view) {
            getDriverReportData();
        }
    }, [company, chain, outlet, startDate, endDate, type, vehicle, driver, permissions])


    return (
        <>
            <div className="card mt-3">
                <div className="card-header p-3 d-flex justify-content-between">
                    <h5 className="card-title">Filters</h5>
                    <div>
                        <button type="button" className="btn btn-secondary me-4" onClick={() => {
                            setCompany(null);
                            setChain(null);
                            setOutlet(null);
                            setDriver(null);
                            setVehicle(null);
                            setType(null);
                            setStartDate(moment().startOf('month').toISOString());
                            setEndDate(moment().endOf('month').toISOString());
                        }}>Reset
                        </button>
                        <button className={'btn btn-primary bg-primary-light'} onClick={() => {
                            setShowFilters(!showFilters);
                        }}><i className={!showFilters ? 'ri-eye-line' : 'ri-eye-off-line'}></i></button>
                    </div>
                </div>
                {showFilters && <div className="card-body">
                    <div className={"row mb-5"}>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Companies</label>
                            <Select
                                name="chain"
                                options={companyOptions}
                                value={company}
                                onChange={setCompany}/>
                        </div>

                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Chains</label>
                            <Select
                                name="chain"
                                options={chainOptions}
                                value={chain}
                                onChange={setChain}/>
                        </div>

                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Outlets</label>
                            <Select
                                name="chain"
                                options={outletOptions}
                                value={outlet}
                                onChange={setOutlet}/>
                        </div>

                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Driver</label>
                            <Select
                                name="chain"
                                options={driverOptions}
                                value={driver}
                                onChange={setDriver}/>
                        </div>

                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Vehicle</label>
                            <Select
                                name="chain"
                                options={vehicleOptions}
                                value={vehicle}
                                onChange={setVehicle}/>
                        </div>

                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Types</label>
                            <Select
                                name="chain"
                                options={typeOptions}
                                value={type}
                                onChange={setType}/>
                        </div>
                    </div>


                </div>}
            </div>

            {/* Date Filters */}
            <div className="card mt-3">
                <div className="card-header p-3 d-flex justify-content-between">
                    <h5 className="card-title">Date Filter</h5>
                    <div>

                        <button className={'btn btn-primary bg-primary-light'} onClick={() => {
                            setShowDateFilter(!showDateFilter);
                        }}><i className={!showDateFilter ? 'ri-eye-line' : 'ri-eye-off-line'}></i></button>
                    </div>
                </div>
                {showDateFilter && <div className="card-body">
                    <div className={"row mb-5"}>
                        <div className={"col-12 mt-5 d-flex justify-content-center w-100"}>
                            <DateRangePicker
                                months={2}
                                ranges={[selectionRange]}
                                direction="horizontal"
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                onChange={(ranges) => {
                                    setSelectionRange(ranges.selection);
                                    setStartDate(moment(ranges.selection.startDate).toISOString());
                                    setEndDate(moment(ranges.selection.endDate).toISOString());
                                }}
                            />
                        </div>
                    </div>


                </div>}
            </div>

            {
                (driver && !vehicle) && <div className="card mt-3">
                    <div className="card-header p-3 d-flex justify-content-between">
                        <h5 className="card-title">Penalty Report</h5>
                        <div>
                            <button className="btn btn-secondary ms-2 me-2" onClick={() => {
                                window.open(encodeURI(`${baseConfig.apiBaseUrl}/report/penalty/list/excel?${$.param({
                                    ...(company ? {company: company.value} : {}),
                                    ...(chain ? {chain: chain.value} : {}),
                                    ...(outlet ? {outlet: outlet.value} : {}),
                                    ...(startDate ? {startDate: startDate} : {}),
                                    ...(endDate ? {endDate: endDate} : {}),
                                    ...(type ? {type: type.value} : {}),
                                    ...(vehicle ? {vehicle: vehicle.value} : {}),
                                    ...(driver ? {driver: driver.value} : {}),
                                    format: 'xlsx',
                                })}`), '_blank');
                            }}>Export Excel
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='table-responsive w-100'>
                            <table
                                id="driver-report"
                                className='report table datatable w-100'
                            >
                                <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Vehicle Number</th>
                                    <th>Chain</th>
                                    <th>Outlet</th>
                                    <th>Registration Number</th>
                                    <th>Date & Time</th>
                                    <th>Type</th>
                                    <th>Final Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>
            }

            {
                vehicle && <div className="card mt-3">
                    <div className="card-header p-3 d-flex justify-content-between">
                        <h5 className="card-title">Penalty Report</h5>
                        <div>
                            <button className="btn btn-secondary ms-2 me-2" onClick={() => {
                                window.open(encodeURI(`${baseConfig.apiBaseUrl}/report/penalty/list/excel?${$.param({
                                    ...(company ? {company: company.value} : {}),
                                    ...(chain ? {chain: chain.value} : {}),
                                    ...(outlet ? {outlet: outlet.value} : {}),
                                    ...(startDate ? {startDate: startDate} : {}),
                                    ...(endDate ? {endDate: endDate} : {}),
                                    ...(type ? {type: type.value} : {}),
                                    ...(vehicle ? {vehicle: vehicle.value} : {}),
                                    ...(driver ? {driver: driver.value} : {}),
                                    format: 'xlsx',
                                })}`), '_blank');
                            }}>Export Excel
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='table-responsive w-100'>
                            <table
                                id="vehicle-report"
                                className='report table datatable w-100'
                            >
                                <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Driver Profile Pic</th>
                                    <th>Driver Name</th>
                                    <th>Chain</th>
                                    <th>Outlet</th>
                                    <th>Registration Number</th>
                                    <th>Date & Time</th>
                                    <th>Type</th>
                                    <th>Final Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>
            }
            {(!driver && !vehicle) && <div className="card mt-3">
                <div className="card-header p-3 d-flex justify-content-between">
                    <h5 className="card-title">Penalty Report</h5>
                    <div>
                        <button className="btn btn-secondary ms-2 me-2" onClick={() => {
                            window.open(encodeURI(`${baseConfig.apiBaseUrl}/report/penalty/list/excel?${$.param({
                                ...(company ? {company: company.value} : {}),
                                ...(chain ? {chain: chain.value} : {}),
                                ...(outlet ? {outlet: outlet.value} : {}),
                                ...(startDate ? {startDate: startDate} : {}),
                                ...(endDate ? {endDate: endDate} : {}),
                                ...(type ? {type: type.value} : {}),
                                ...(vehicle ? {vehicle: vehicle.value} : {}),
                                ...(driver ? {driver: driver.value} : {}),
                                format: 'xlsx',
                            })}`), '_blank');
                        }}>Export Excel
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <div className='table-responsive w-100'>
                        <table
                            id="company-report"
                            className='report table datatable w-100'
                        >
                            <thead>

                            <tr>
                                <th>S.No</th>
                                <th>Driver PF</th>
                                <th>Driver Name</th>
                                <th>Vehicle</th>
                                <th>Chain</th>
                                <th>Outlet</th>
                                <th>Registration Number</th>
                                <th>Date & Time</th>
                                <th>Type</th>
                                <th>Final Amount</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>}
        </>
    )
}

export default ManagePenaltyReports;
