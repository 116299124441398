import {useEffect, useState} from "react";
import {PenaltyTypeService} from "../../services/PenaltyType";
import Select from "react-select";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import toast from "react-hot-toast";

const EditPenaltyType = () => {
    const admin = useSelector((state) => state.auth);
    const {id} = useParams();
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/penalty-types')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);


    const loadDetails = async () => {
        const blog = await PenaltyTypeService.get(id);
        console.log(blog);
        if (blog) {
            setTitle(blog.title);
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!permissions.edit) {
            toast.error('You are not allowed to perform this action');
            return;
        }
        setLoading(true);
        const data = {
            title,
        };
        const res = await PenaltyTypeService.update(id, data);
        if (res) {
            e.target.reset();
        }
        setLoading(false);
    };

    useEffect(() => {
        loadDetails();
    }, [id]);

    return (
        <>
            <div className="card">
                <div className="card-header p-3">
                    <h5 className="card-title">Edit Penalty Type</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Title <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="title" name="title" placeholder="Type Title" value={title}
                                           onChange={(e) => setTitle(e.target.value)} required={true}/>
                                </div>
                            </div>

                        </div>

                        <div className={'d-flex'}>
                            <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                            </button>
                            <button type="button" className="btn btn-secondary me-2" disabled={loading} onClick={() => {
                                window.history.back();
                            }}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Back
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </>
    );
}

export default EditPenaltyType;
