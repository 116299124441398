import React, {useEffect, useState} from "react";
import Select from "react-select";
import {PermissionService} from "../../services/PermissionService";
import $ from "jquery";
import 'datatables.net-bs4';
import 'datatables.net';
import {useSelector} from "react-redux";
import toast from "react-hot-toast";

const AddPermission = () => {
    const admin = useSelector((state) => state.auth);
    const [title, setTitle] = useState("");
    const [routes, setRoutes] = useState([]);
    const [routeOptions, setRouteOptions] = useState([
        {
            label: "Dashboard", options: [
                {value: "/", label: "All Charts"},
                {value: "/dashboard/c1", label: "Total Vehicle Expenses By Category"},
                {value: "/dashboard/c2", label: "Average Vehicle Expenses By Month"},
                {value: "/dashboard/c3", label: "Daily Vehicle Expenses"},
                {value: "/dashboard/c4", label: "Total Expenses By Company"},
                {value: "/dashboard/c5", label: "Total Expenses By Chain"},
                {value: "/dashboard/c6", label: "Total Expenses By Outlet"},
                {value: "/dashboard/c7", label: "Total Expenses By Purpose"},
                {value: "/dashboard/c8", label: "Penalties By Type"},
                {value: "/dashboard/c9", label: "Penalties By Chain"},
                {value: "/dashboard/c10", label: "Penalties By Driver"},
                {value: "/dashboard/c11", label: "Penalties By Vehicle"},
                {value: "/dashboard/c12", label: "Top Expensive Vehicles"},
                {value: "/dashboard/c13", label: "Top Expensive Drivers"},
                {value: "/dashboard/c14", label: "Total Expense Vehicle By Category"},
                {value: "/dashboard/c15", label: "Total Expense Vehicle By Type"},
                {value: "/dashboard/c16", label: "Total Outlet Expense By Vehicle"},
                {value: "/dashboard/c17", label: "Total Vehicle Expense By Outlet"},
            ]
        },
        {
            label: "Entity", options: [
                {value: "/companies", label: "Company Management"},
            ]
        },
        {
            label: "Resource", options: [
                {value: "/vehicles", label: "Vehicle Management"},
                {value: "/employees", label: "Employee Management"},
                {value: "/permissions", label: "Permission Management"},
                {value: "/vehicle-assignments", label: "Vehicle Assignment Management"},
            ]
        },
        {
            label: "Expense", options: [
                {value: "/expense-categories", label: "Expense Categories Management"},
                {value: "/expense-types", label: "Expense Types Management"},
                {value: "/expenses", label: "Expense Management"},
            ]
        },
        {
            label: "Report", options: [
                {value: "/reports", label: "All Report Management"},
                {value: "/reports/by-driver", label: "Driver Report Management"},
                {value: "/reports/by-vehicle", label: "Vehicle Report Management"},
                {value: "/reports/penalty", label: "Penalty Report Management"},
            ]
        },
        {
            label: "Penalty", options: [
                {value: "/penalties", label: "Penalties Management"},
            ]
        }
    ]);
    const [addPermission, setAddPermission] = useState(false);
    const [editPermission, setEditPermission] = useState(false);
    const [viewPermission, setViewPermission] = useState(false);
    const [deletePermission, setDeletePermission] = useState(false);
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/permissions')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!permissions.add) {
            toast.error('You are not allowed to perform this action');
            return;
        }
        const data = {
            title,
            routes: routes.map((route) => route.value),
            add: addPermission,
            edit: editPermission,
            view: viewPermission,
            delete: deletePermission
        };
        const res = await PermissionService.add(data);
        if (res) {
            e.target.reset();
            setRoutes([]);
            setTitle("");
            setAddPermission(false);
            setEditPermission(false);
            setViewPermission(false);
            setDeletePermission(false);
        }
        setLoading(false);
    }

    useEffect(() => {
        try {
            $('#table').DataTable();
        } catch (e) {
            console.log(e);
        }
    }, []);

    return (
        <>
            <div className="card">
                <div className="card-header p-3">
                    <h5 className="card-title">Add Permission</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Title <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="title" name="title"
                                           placeholder="Type Title"
                                           onChange={(e) => setTitle(e.target.value)} required={true}/>
                                </div>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-12">
                                <label htmlFor="type" className="form-label">Modules</label>
                                <Select
                                    name="routes"
                                    options={routeOptions}
                                    value={routes}
                                    isMulti={true}
                                    onChange={setRoutes} required={true}/>
                            </div>
                            <div className="col-12 mt-3 mb-5">
                                <h5>Permissions</h5>
                                <div className='table-responsive w-100'>
                                    <table
                                        id="table"
                                        className='table datatable table-bordered table-hover w-100'
                                    >
                                        <thead className="bg-secondary text-white">
                                        <tr>
                                            <th>Add</th>
                                            <th>Edit</th>
                                            <th>View</th>
                                            <th>Delete</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" style={{
                                                        width: '60px', height: '30px', borderRadius: '15px'
                                                    }} type="checkbox" role="switch" checked={addPermission}
                                                           id="add"
                                                           onChange={(e) => setAddPermission(e.target.checked)}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" style={{
                                                        width: '60px', height: '30px', borderRadius: '15px'
                                                    }} type="checkbox" role="switch" checked={editPermission}
                                                           id="edit"
                                                           onChange={(e) => setEditPermission(e.target.checked)}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" style={{
                                                        width: '60px', height: '30px', borderRadius: '15px'
                                                    }} type="checkbox" role="switch" checked={viewPermission}
                                                           id="view"
                                                           onChange={(e) => setViewPermission(e.target.checked)}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" style={{
                                                        width: '60px', height: '30px', borderRadius: '15px'
                                                    }} type="checkbox" role="switch" checked={deletePermission}
                                                           id="delete"
                                                           onChange={(e) => setDeletePermission(e.target.checked)}/>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                        <div className={'d-flex'}>
                            <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                            </button>
                            <button type="button" className="btn btn-secondary me-2" disabled={loading} onClick={() => {
                                window.history.back();
                            }}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Back
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </>
    );
}

export default AddPermission;
