import {useEffect, useState} from "react";
import Select from "react-select";
import {ChainService} from "../../services/ChainService";
import {OutletService} from "../../services/OutletService";
import {useSelector} from "react-redux";
import toast from "react-hot-toast";

const AddOutlet = ({company, chains, onAdd}) => {
    const admin = useSelector((state) => state.auth);
    const [location, setLocation] = useState('');
    const [code, setCode] = useState('');
    const [chain, setChain] = useState(null);
    const [loading, setLoading] = useState(false);
    const [allowedPermissions, setAllowedPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/companies')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setAllowedPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!allowedPermissions.add) {
            toast.error('You are not allowed to perform this action');
            return;
        }
        setLoading(true);
        console.log(chain);
        const data = {
            location,
            code,
            company,
            chain: chain.value
        };
        const res = await OutletService.add(data);
        setLoading(false);
        onAdd();
    };


    return (
        <>
            <div className="card mb-5">
                <div className="card-header p-3">
                    <h5 className="card-title">Add Outlet</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Outlet Location <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="location" name="location" placeholder="Outlet Location"
                                           onChange={(e) => setLocation(e.target.value)} required={true}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Outlet Code <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="code" name="code" placeholder="Outlet Code"
                                           onChange={(e) => setCode(e.target.value)} required={true}/>
                                </div>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-6">
                                <label htmlFor="type" className="form-label">Chain</label>
                                <Select
                                    name="chain"
                                    options={chains}
                                    value={chain}
                                    onChange={setChain} required={true} />
                            </div>
                        </div>

                        <div className={'d-flex'}>
                            <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                            </button>
                            <button type="button" className="btn btn-secondary me-2" disabled={loading} onClick={() => {
                                window.history.back();
                            }}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Back
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </>
    );
}

export default AddOutlet;
