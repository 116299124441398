import baseConfig from "../Config";
import axios from "axios";
import toast from "react-hot-toast";

export class PenaltyTypeService {
    static async add(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                await axios.post(`${baseConfig.apiBaseUrl}/penalty-type`, body, config);
                toast.success("Penalty type added successfully", {});
                return true;
            } catch (err) {
                if (err.response) {
                    toast.error(err.response.data.message, {});
                    return false;
                }
            }
        }
    }

    static get = async (id) => {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            try {
                const res = await axios.get(
                    `${baseConfig.apiBaseUrl}/penalty-type/${id}`,
                    config
                );
                return res.data;
            } catch (err) {
                if (err.response) {
                    toast.error(err.response.data.message, {});
                }
                return false;
            }
        }
    };

    static async update(id, data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                await axios.patch(`${baseConfig.apiBaseUrl}/penalty-type/${id}`, body, config);
                toast.success("Penalty type updated successfully", {});
                return true;
            } catch (err) {
                if (err.response) {
                    toast.error(err.response.data.message, {});
                    return false;
                }
            }
        }
    }

    static types = async () => {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            try {
                const res = await axios.get(
                    `${baseConfig.apiBaseUrl}/penalty-type/list/all`,
                    config
                );
                return res.data;
            } catch (err) {
                if (err.response) {
                    toast.error(err.response.data.message, {});
                }
                return false;
            }
        }
    }
}
