import {useSelector} from "react-redux";
import {AuthService} from "../services/AuthService";
import store from "../store";
import {Link} from "react-router-dom";
import {useEffect} from "react";
import {useNavigate} from "react-router";
const Header = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth);

    useEffect(() => {
    //    Check if its mobile view
        if (window.innerWidth < 992) {
            const menuSidebar = document.getElementById('menuSidebar');
            const body = document.querySelector('body');
            menuSidebar.addEventListener('click', function (e) {
                e.preventDefault();
                // Get sidebar from classname "sidebar" and set left to 0, opacity to 1 and visibility to visible
                document.querySelector('.sidebar').style.left = '0';
                document.querySelector('.sidebar').style.opacity = '1';
                document.querySelector('.sidebar').style.visibility = 'visible';
            });

            // Close sidebar when click outside of it
            const content = document.querySelector('.main-app');
            content.addEventListener('click', function (e) {
                console.log(e);
                document.querySelector('.sidebar').style.left = '-300px';
                document.querySelector('.sidebar').style.opacity = '0';
                document.querySelector('.sidebar').style.visibility = 'hidden';
            });
        }
    }, []);

    return <>
        <div className="header-main px-3 px-lg-4 d-flex justify-content-between">
            <a id="menuSidebar" href="#" className="menu-link me-3 me-lg-4"><i className="ri-menu-2-fill"></i></a>

           <div className={'d-flex justify-content-end'}>
               {/*<div className="dropdown dropdown-notification ms-3 ms-xl-4">*/}
               {/*    <a href="" className="dropdown-link" data-bs-toggle="dropdown"*/}
               {/*       data-bs-auto-close="outside"><small>0</small><i className="ri-notification-3-line"></i></a>*/}
               {/*    <div className="dropdown-menu dropdown-menu-end mt-10-f me--10-f">*/}
               {/*        <div className="dropdown-menu-header">*/}
               {/*            <h6 className="dropdown-menu-title">Notifications</h6>*/}
               {/*        </div>*/}

               {/*        <ul className="list-group">*/}

               {/*        </ul>*/}
               {/*        <div className="dropdown-menu-footer"><a href="">Show all Notifications</a></div>*/}
               {/*    </div>*/}
               {/*</div>*/}
               <div className="dropdown dropdown-profile ms-3 ms-xl-4">
                   <a href="" className="dropdown-link" data-bs-toggle="dropdown" data-bs-auto-close="outside">
                       <div className="avatar online"><img src={`https://ui-avatars.com/api/?background=EDAE10&color=fff&name=${user.name}&size=40&rounded=true`} alt=""/></div>
                   </a>
                   <div className="dropdown-menu dropdown-menu-end mt-10-f">
                       <div className="dropdown-menu-body">
                           <div className="avatar avatar-xl online mb-3"><img src={`https://ui-avatars.com/api/?background=EDAE10&color=fff&name=${user.name}&size=40&rounded=true`} alt=""/></div>
                           <h5 className="mb-1 text-dark fw-semibold">{user.name}</h5>
                           <p className="fs-sm text-secondary">@{user.username}</p>

                           <nav className="nav">
                               <Link to={'/auth/edit-profile'}><i className="ri-edit-2-line"></i> Edit Profile</Link>
                           </nav>
                           <hr/>
                           <nav className="nav">
                               <a href="" onClick={() => {
                                   store.dispatch(AuthService.logout());
                                   navigate('/');
                               }}><i className="ri-logout-box-r-line"></i> Log Out</a>
                           </nav>
                       </div>
                   </div>
               </div>
           </div>
        </div>
    </>;
}

export default Header;
