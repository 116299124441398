import {useEffect, useState} from "react";
import {UploadService} from "../../services/UploadService";
import {ChainService} from "../../services/ChainService";
import {useSelector} from "react-redux";
import toast from "react-hot-toast";

const AddChain = ({company, onAdd}) => {
    const admin = useSelector((state) => state.auth);
    const [address, setAddress] = useState('');
    const [code, setCode] = useState('');
    const [logo, setLogo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [allowedPermissions, setAllowedPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/companies')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setAllowedPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!allowedPermissions.add) {
            toast.error('You are not allowed to perform this action');
            return;
        }
        setLoading(true);
        let logoUrl = "";
        if (logo !== null) {
            logoUrl = await uploadFile(logo);
        }
        const data = {
            address,
            code,
            company,
            logo: logoUrl,
        };
        const res = await ChainService.add(data);
        if (res) {
            e.target.reset();
        }
        setLoading(false);
        onAdd();
    };

    const uploadFile = async (file) => {
        let formData = new FormData();
        formData.append("files", file);
        const urls = await UploadService.upload(formData);
        console.log(urls);
        return urls.length > 0 ? urls[0] : '';
    }


    return (
        <>
            <div className="card mb-5">
                <div className="card-header p-3">
                    <h5 className="card-title">Add Chain</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Chain Name <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Address"
                                           onChange={(e) => setAddress(e.target.value)} required={true}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Chain Code <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="code" name="code" placeholder="Code"
                                           onChange={(e) => setCode(e.target.value)} required={true}/>
                                </div>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-6">
                                <label htmlFor="image" className="form-label">Chain Logo <span
                                    className='text-danger'>(required)</span></label>
                                <input type="file" className="form-control" id="logo" name="logo"
                                       onChange={(e) => setLogo(e.target.files[0])} required={true}/>
                            </div>

                        </div>

                        <div className={'d-flex'}>
                            <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                            </button>
                            <button type="button" className="btn btn-secondary me-2" disabled={loading} onClick={() => {
                                window.history.back();
                            }}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Back
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </>
    );
}

export default AddChain;
