import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net';
import {useLocation, useNavigate} from "react-router";

import baseConfig from "../../Config";
import {VehicleAssignmentService} from "../../services/VehicleAssignmentService";
import toast from "react-hot-toast";


const ManageVehicleAssignments = () => {
    const admin = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/vehicle-assignments')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    useEffect(() => {
        if (permissions.view) {
            getItems();
        }
    }, [permissions]);

    const getItems = () => {
        let companies = [], chains = [], outlets = [];
        if (admin.permissions.find((item) => item.title !== 'Super Admin')) {
            companies = admin.companies.map((company) => company._id);
            chains = admin.chains.map((chain) => chain._id);
            outlets = admin.outlets.map((outlet) => outlet._id);
        }
        try {
            $('#items').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        let token = localStorage.getItem('token')
        $('#items').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        return `<p class="text-primary" style="cursor:pointer;">${data[0]}</p>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            navigate(`/employees/edit/${cellData[1]}`);
                        })
                    },
                    "targets": 2
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            if (!permissions.view) {
                                toast.error('You do not have permission to delete vehicle assignment');
                                return;
                            }
                            navigate(`/vehicle-assignments/edit/${cellData}`);
                        })
                    },
                    "targets": 8
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger">Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            if (!permissions.delete) {
                                toast.error('You do not have permission to delete vehicle assignment');
                                return;
                            }
                            const data = {
                                deleted: true
                            }
                            await VehicleAssignmentService.update(rowData[9], data);
                            getItems();
                        })
                    },
                    "targets": 9
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/assign-vehicle/list`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify({
                        ...d,
                        ...(companies.length > 0 ? {companies} : {}),
                        ...(chains.length > 0 ? {chains} : {}),
                        ...(outlets.length > 0 ? {outlets} : {}),
                    });
                },
            }
        });
    }

    return (
        <div>

            <div className="card mt-5">
                <div className="card-header d-flex justify-content-between p-3">
                    <h5 className="card-title">Manage Vehicle Assignments</h5>
                    <div className="card-tools">
                        {permissions.add && <button type="button" className="btn btn-secondary"
                                                    onClick={() => navigate(`${location.pathname}/add`)}>Assign
                            vehicle</button>}
                    </div>
                </div>
                <div className="card-body">
                    {
                        !permissions.view &&
                        <p className='text-center'>You do not have permission to view vehicle assignments</p>
                    }
                    {permissions.view && <div className='table-responsive w-100'>
                        <table
                            id="items"
                            className='table datatable w-100'
                        >
                            <thead>
                            <tr>
                                <th>Vehicle Name</th>
                                <th>Vehicle Number</th>
                                <th>Driver</th>
                                <th>Company</th>
                                <th>Chain</th>
                                <th>Outlet</th>
                                <th>Shift</th>
                                <th>Assigned Date</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>}


                </div>
            </div>
        </div>
    );
}

export default ManageVehicleAssignments;
