import { useSelector } from "react-redux";
import Login from "../pages/auth/Login";
import Base from "../pages/Base";

const AuthMiddleware = ({ children }) => {
    const token = useSelector((state) => state.auth.token)

    return (
        token != null ? <Base>
            {children}
        </Base> : <Login />
    )
}

export default AuthMiddleware
