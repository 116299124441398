import {useState} from "react";
import store from "../../store";
import {AuthService} from "../../services/AuthService";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        store.dispatch(AuthService.login(username, password));
    }

  return (
      <div className={'page-sign'} style={{backgroundImage: 'url("/assets/img/auth-bg.jpg")', backgroundSize: "cover"}}>
          <div className="card card-sign">
              <div className="card-header">
                  <a href="../" className="header-logo mb-4">VMS</a>
                  <h3 className="card-title">Sign In</h3>
                  <p className="card-text">Welcome back! Please sign-in to continue.</p>
              </div>
              <form className="card-body" onSubmit={handleSubmit}>
                  <div className="mb-4">
                      <label className="form-label">Email address/Username</label>
                      <input type="text" className="form-control" placeholder="Enter your email address or username" onChange={(e) => setUsername(e.target.value)} />
                  </div>
                  <div className="mb-4">
                      {/*<label className="form-label d-flex justify-content-between">Password <a href="">Forgot password?</a></label>*/}
                      <input type="password" className="form-control" placeholder="Enter your password" onChange={(e) => setPassword(e.target.value)}  />
                  </div>
                  <button className="btn btn-primary btn-sign">Sign In</button>

              </form>
          </div>
      </div>
  );
}

export default Login;
