import {useEffect, useState} from "react";
import {UploadService} from "../../services/UploadService";
import {CompanyService} from "../../services/CompanyService";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";

const AddCompany = () => {
    const admin = useSelector((state) => state.auth);
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [logo, setLogo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/companies')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!permissions.edit) {
            toast.error('You are not allowed to perform this action');
            return;
        }
        setLoading(true);
        let logoUrl = "";
        if (logo !== null) {
            logoUrl = await uploadFile(logo);
        }
        const data = {
            name,
            code,
            logo: logoUrl,
        };
        const res = await CompanyService.add(data);
        if (res) {
            e.target.reset();
        }
        setLoading(false);
    };


    const uploadFile = async (file) => {
        let formData = new FormData();
        formData.append("files", file);
        const urls = await UploadService.upload(formData);
        console.log(urls);
        return urls.length > 0 ? urls[0] : '';
    }

    return (
        <>
            <div className="card">
                <div className="card-header p-3">
                    <h5 className="card-title">Add Company</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Company Name <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Name"
                                           onChange={(e) => setName(e.target.value)} required={true}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Company Code <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="code" name="code" placeholder="Code"
                                           onChange={(e) => setCode(e.target.value)} required={true}/>
                                </div>
                            </div>

                            <div className="mb-3 col-sm-12 col-md-12">
                                <label htmlFor="image" className="form-label">Company Logo <span
                                    className='text-danger'>(required)</span></label>
                                <input type="file" className="form-control" id="logo" name="logo"
                                       onChange={(e) => setLogo(e.target.files[0])} required={true}/>
                            </div>

                        </div>

                        <div className={'d-flex'}>
                            <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                            </button>
                            <button type="button" className="btn btn-secondary me-2" disabled={loading} onClick={() => {
                                window.history.back();
                            }}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Back
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </>
    );
}

export default AddCompany;
