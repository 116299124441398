import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net';
import { useLocation, useNavigate } from "react-router";

import baseConfig from "../../Config";
import { CompanyService } from "../../services/CompanyService";
import { ChainService } from "../../services/ChainService";
import { OutletService } from "../../services/OutletService";
import Select from "react-select";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { ExpenseService } from "../../services/ExpenseService";
import toast from "react-hot-toast";
import { ExpenseCategoryService } from "../../services/ExpenseCategory";
import { ExpenseTypeService } from "../../services/ExpenseType";
import * as moment from "moment";
import Modal from 'react-bootstrap/Modal';
import ManageExpenseHistory from "./ManageExpenseHistory";



const ManageExpenses = () => {
    const admin = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const [companies, setCompanies] = useState([]);
    const [chains, setChains] = useState([]);
    const [outlets, setOutlets] = useState([]);
    const [categories, setCategories] = useState([]);
    const [types, setTypes] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [rejectReason, setRejectReason] = useState('');
    const [chainOptions, setChainOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [outletOptions, setOutletOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [selectionRange, setSelectionRange] = useState(
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
    );
    const [expenses, setExpenses] = useState([]);
    const statusOptions = [
        { label: 'Pending', value: 'Pending' },
        { label: 'Manager Approved', value: 'Manager Approved' },
        { label: 'Manager Rejected', value: 'Manager Rejected' },
        { label: 'Operational Manager Approved', value: 'Operational Manager Approved' },
        { label: 'Operational Manager Rejected', value: 'Operational Manager Rejected' },
        { label: 'Completed', value: 'Completed' },
    ];
    const [showHistory, setShowHistory] = useState(false);
    const [expenseId, setExpenseId] = useState(null);
    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });
    const [selectedStatus, setSelectedStatus] = useState(null);

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/expenses')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    const getCompanies = async () => {
        const allItems = await CompanyService.companies();
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.companies.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({ label: allItems[i].name, value: allItems[i]._id });
            }
        }
        setCompanyOptions(items);
    }

    const getChains = async () => {
        const allItems = await ChainService.chains(companies.map((i) => i.value));
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.chains.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({ label: allItems[i].address, value: allItems[i]._id });
            }
        }
        setChainOptions(items);
    }

    const getOutlets = async () => {
        const allItems = await OutletService.outlets(chains.map((i) => i.value));
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.outlets.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({ label: allItems[i].location, value: allItems[i]._id });
            }
        }
        setOutletOptions(items);
    }

    const getCategories = async () => {
        const allItems = await ExpenseCategoryService.categories();
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            items.push({ label: allItems[i].title, value: allItems[i]._id });
        }
        setCategoryOptions(items);
    }

    const getTypes = async () => {
        const items = [];
        for (let i = 0; i < categories.length; i++) {
            const allItems = await ExpenseTypeService.types([categories[i].value]);
            for (let i = 0; i < allItems.length; i++) {
                items.push({ label: allItems[i].title, value: allItems[i]._id });
            }
        }
        setTypeOptions(items);
    }

    useEffect(() => {
        getCompanies();
        getCategories();
    }, []);

    useEffect(() => {
        if (companies.length > 0) {
            getChains();
        }
    }, [companies])

    useEffect(() => {
        if (chains.length > 0) {
            getOutlets();
        }
    }, [chains])

    useEffect(() => {
        if (categories.length > 0) {
            getTypes();
        }
    }, [categories])

    useEffect(() => {
        if (permissions.view) {
            getData();
        }
    }, [companies, chains, outlets, selectionRange, categories, types, statuses, permissions]);

    const updateStatus = async (status) => {
        if (!permissions.edit) {
            toast.error('You are not allowed to perform this action');
            return;
        }
        console.log(expenses);
        const data = [];
        expenses.forEach((expense) => {
            if (expense.selected) {
                data.push({
                    id: expense.id,
                    status: status,
                    ...(status === 'Manager Rejected' || status === 'Operational Manager Rejected' ? { reason: rejectReason } : {})
                });
            }
        });
        const res = await ExpenseService.bulkStatusUpdate({
            expenses: data
        });
        if (res) {
            setRejectReason('');
            getData();
        }
    }

    useEffect(() => {
        console.log(expenseId);
        if (expenseId != null) {
            $('#historyModal').addClass('show');
            $('#historyModal').css('display', 'block');
        }
    }, [expenseId]);

    const getData = () => {
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        let token = localStorage.getItem('token')
        $('#table').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<div class="figure"><img src="${data}" style="width: 100px;height: 150px; border-radius: 5px; object-fit: contain"></div>`;
                        }
                        return '<div></div>';
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        // Onclick show large image
                        $(td).click(e => {
                            console.log(cellData);
                            if (cellData) {
                                $('#imageModal').addClass('show');
                                $('#imageModal').css('display', 'block');
                                $('#imageModal').find('img').attr('src', cellData);
                            }
                        });
                    },
                    "targets": 1
                },
                {

                    "render": function (data, type, row) {
                        return `<input class="form-check-input" type="checkbox" id="invoice-${data}">`;
                    },
                    "orderable": false,
                    "createdCell": (td, cellData, rowData, row, col) => {
                        // Check if exists than update and if not then add
                        $(td).click(e => {
                            expenses.find((expense) => expense.id === cellData).selected = e.target.checked;
                            console.log(expenses.find((expense) => expense.id === cellData));
                            setExpenses([...expenses]);
                        });
                        if (expenses.filter((expense) => expense.id === cellData).length > 0) {
                            expenses.find((expense) => expense.id === cellData).selected = false;
                            return;
                        }
                        expenses.push(
                            {
                                id: cellData,
                                selected: false
                            }
                        );
                        setExpenses([...expenses]);
                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        return `<p >#${data}</p>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 2
                },
                {

                    "render": function (data, type, row) {
                        return `<p class="text-primary"><b>KWD ${data}</b></p>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 6
                },
                {

                    "render": function (data, type, row) {
                        return `<p class="text-primary" style="cursor: pointer">${data[1]}</p>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            navigate(`/employees/edit/${cellData[0]}`);
                        })
                    },
                    "targets": 7
                },
                {

                    "render": function (data, type, row) {
                        return `<p class="badge ${data.includes('Rejected') ? 'bg-danger' : data.includes('Pending') ? 'bg-primary' : 'bg-success'} p-2 rounded-1">${data}</p>`;
                    },
                    "orderable": false,
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(document).ready(function () {
                            console.log(row);
                            $(`#${rowData[0]}-status`).change(async function (e) {
                                console.log(e);
                                const data = [{
                                    id: rowData[0],
                                    status: e.target.value
                                }];
                                const res = await ExpenseService.bulkStatusUpdate({
                                    expenses: data
                                });

                            });
                        });
                    },
                    "targets": 8
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">History</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            setExpenseId(cellData);
                        })
                    },
                    "targets": 10
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/expense/list`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify({
                        ...d,
                        ...(companies.length > 0 ? { companies: companies.map((i) => i.value) } : {}),
                        ...(chains.length > 0 ? { chains: chains.map((i) => i.value) } : {}),
                        ...(outlets.length > 0 ? { outlets: outlets.map((i) => i.value) } : {}),
                        ...(startDate ? { startDate: startDate } : {}),
                        ...(endDate ? { endDate: endDate } : {}),
                        ...(categories.length > 0 ? { categories: categories.map((i) => i.value) } : {}),
                        ...(types.length > 0 ? { types: types.map((i) => i.value) } : {}),
                        ...(statuses.length > 0 ? { statuses: statuses.map((i) => i.value) } : {}),
                    });
                },
            }
        });
    }

    return (
        <div>
            <div className="card mt-3">
                <div className="card-header p-3 d-flex justify-content-between">
                    <h5 className="card-title">Filters</h5>
                    <div className={'d-flex'}>
                        <button type="button" className="btn btn-secondary me-4" onClick={() => {
                            setCompanies([]);
                            setChains([]);
                            setOutlets([]);
                            setCategories([]);
                            setTypes([]);
                            setStatuses([]);
                            setStartDate(moment().startOf('month').toISOString());
                            setEndDate(moment().endOf('month').toISOString());
                        }}>Reset
                        </button>
                        <button className="btn btn-primary ms-2 me-2" onClick={() => {
                            const queryParams = [];

                            if (companies.length > 0) queryParams.push(`companies=${companies.map((i) => i.value).join(',')}`);
                            if (chains.length > 0) queryParams.push(`chains=${chains.map((i) => i.value).join(',')}`);
                            if (outlets.length > 0) queryParams.push(`outlets=${outlets.map((i) => i.value).join(',')}`);
                            if (startDate) queryParams.push(`startDate=${startDate}`);
                            if (endDate) queryParams.push(`endDate=${endDate}`);
                            if (categories.length > 0) queryParams.push(`categories=${categories.map((i) => i.value).join(',')}`);
                            if (types.length > 0) queryParams.push(`types=${types.map((i) => i.value).join(',')}`);
                            if (statuses.length > 0) queryParams.push(`statuses=${statuses.map((i) => i.value).join(',')}`);
                            
                            queryParams.push(`format=xlsx`);
                            
                            const query = queryParams.join('&');
                            const url = `${baseConfig.apiBaseUrl}/expense/export/excel?${query}`;
                            window.open(encodeURI(url), '_blank');
                        }}>Export Excel
                        </button>
                    </div>

                </div>
                <div className="card-body">
                    <div className={"row mb-5"}>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Companies</label>
                            <Select
                                name="chain"
                                options={companyOptions}
                                value={companies}
                                onChange={setCompanies} isMulti={true}/>
                        </div>

                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Chains</label>
                            <Select
                                name="chain"
                                options={chainOptions}
                                value={chains}
                                onChange={setChains} isMulti={true}/>
                        </div>

                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Outlets</label>
                            <Select
                                name="chain"
                                options={outletOptions}
                                value={outlets}
                                onChange={setOutlets} isMulti={true}/>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Categories</label>
                            <Select
                                name="chain"
                                options={categoryOptions}
                                value={categories}
                                onChange={setCategories} isMulti={true}/>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Types</label>
                            <Select
                                name="chain"
                                options={typeOptions}
                                value={types}
                                onChange={setTypes} isMulti={true}/>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Status</label>
                            <Select
                                name="chain"
                                options={statusOptions}
                                value={statuses}
                                onChange={setStatuses} isMulti={true}/>
                        </div>
                        <div className={"col-12 mt-5 d-flex justify-content-center w-100"}>
                            <DateRangePicker
                                months={2}
                                ranges={[selectionRange]}
                                direction="horizontal"
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                onChange={(ranges) => {
                                    setSelectionRange(ranges.selection);
                                    setStartDate(moment(ranges.selection.startDate).toISOString());
                                    setEndDate(moment(ranges.selection.endDate).toISOString());
                                }}
                            />
                        </div>
                    </div>


                </div>
            </div>
            <div className="card mt-3">
                <div className="card-header p-3">
                    <h5 className="card-title">Manage Expenses</h5>
                </div>
                <div className="card-body">
                    <div className={"row"}>
                        <div className="mb-3 col-12">
                            <label htmlFor="type" className="form-label">Bulk Status Update</label>
                            <Select
                                name="status"
                                options={statusOptions}
                                value={selectedStatus}
                                onChange={async (e) => {
                                    if (e.value.includes('Rejected') && rejectReason.length === 0) {
                                        toast.error('Please enter reject reason');
                                        return;

                                    }
                                    await updateStatus(e.value);
                                    // setSelectedStatus(null);
                                }}/>
                            <textarea className="form-control mt-3" placeholder={'Reject Reason'} value={rejectReason}
                                      onChange={(e) => {
                                          setRejectReason(e.target.value);
                                      }}/>
                        </div>

                    </div>
                    <div className='table-responsive w-100'>
                        <table
                            id="table"
                            className='table datatable w-100'
                        >
                            <thead>
                            <tr>
                                <th><input type={'checkbox'} onChange={(e) => {
                                    expenses.forEach((expense) => {
                                        expense.selected = e.target.checked;
                                        $(`#invoice-${expense.id}`).prop('checked', e.target.checked);
                                    })
                                }}/></th>
                                <th>Invoice</th>
                                <th>Invoice ID</th>
                                <th>Date</th>
                                <th>Category</th>
                                <th>Type</th>
                                <th>Amount</th>
                                <th>Driver</th>
                                <th>Status</th>
                                <th>Reject Reason</th>
                                <th>History</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
            <div
                className="modal show" id="imageModal" tabIndex="-1" aria-labelledby="imageModalLabel"
                aria-hidden="true"
                style={{display: 'none'}}
            >
                <Modal.Dialog>
                    <Modal.Header>
                        <Modal.Title>Invoice Preview</Modal.Title>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => {
                                    $('#imageModal').removeClass('show');
                                    $('#imageModal').css('display', 'none');
                                }}></button>
                    </Modal.Header>

                    <Modal.Body>
                        <img src="" alt="" style={{width: '100%', height: '100%'}}/>
                    </Modal.Body>
                </Modal.Dialog>
            </div>
            <div
                className="modal modal-xl" id="historyModal" tabIndex="-1" aria-labelledby="historyModalLabel"
                aria-hidden="true"
                style={{display: 'none'}}
            >
                <Modal.Dialog className="w-100">
                    <Modal.Header>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => {
                                    $('#historyModal').removeClass('show');
                                    $('#historyModal').css('display', 'none');
                                    setExpenseId(null);
                                }}></button>
                    </Modal.Header>

                    <Modal.Body className="w-100">
                        {expenseId && <ManageExpenseHistory id={expenseId}/>}
                    </Modal.Body>
                </Modal.Dialog>
            </div>
        </div>
    );
}

export default ManageExpenses;
