import {useParams} from "react-router";
import $ from "jquery";
import React, {useEffect} from "react";
import baseConfig from "../../Config";
import {ExpenseService} from "../../services/ExpenseService";

const  ManageExpenseHistory = ({id}) => {
    // const { id } = useParams();

    const getData = () => {
        try {
            $('#history').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        let token = localStorage.getItem('token')
        $('#history').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        return `<p class="badge ${data.includes('Rejected') ? 'bg-danger' : data.includes('Pending') ? 'bg-primary' : 'bg-success'} p-2 rounded-1">${data}</p>`;
                    },
                    "orderable": false,
                    "targets": 1
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/expense/activities`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify({
                        ...d,
                        expense: id,
                    });
                },
            }
        });
    }

    useEffect(() => {
        getData();
    }, [id]);

    return (
        <div className="card mt-3">
            <div className="card-header p-3">
                <h5 className="card-title">Expense History</h5>
            </div>
            <div className="card-body">
                <div className='table-responsive w-100'>
                    <table
                        id="history"
                        className='table datatable w-100'
                    >
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Added By</th>
                        </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    );
}

export default ManageExpenseHistory;