import baseConfig from "../Config";
import axios from "axios";
import toast from "react-hot-toast";

export class ExpenseCategoryService {
    static async add(data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                await axios.post(`${baseConfig.apiBaseUrl}/expense-category`, body, config);
                toast.success("Expense category added successfully", {});
                return true;
            } catch (err) {
                if (err.response) {
                    toast.error(err.response.data.message, {});
                    return false;
                }
            }
        }
    }

    static get = async (id) => {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            try {
                const res = await axios.get(
                    `${baseConfig.apiBaseUrl}/expense-category/${id}`,
                    config
                );
                return res.data;
            } catch (err) {
                if (err.response) {
                    toast.error(err.response.data.message, {});
                }
                return false;
            }
        }
    };

    static async update(id, data) {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            const body = JSON.stringify(data);
            try {
                await axios.patch(`${baseConfig.apiBaseUrl}/expense-category/${id}`, body, config);
                toast.success("Expense category updated successfully", {});
                return true;
            } catch (err) {
                if (err.response) {
                    toast.error(err.response.data.message, {});
                    return false;
                }
            }
        }
    }

    static categories = async () => {
        let token = localStorage.getItem("token");
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            };
            try {
                const res = await axios.get(
                    `${baseConfig.apiBaseUrl}/expense-category/list/all`,
                    config
                );
                return res.data;
            } catch (err) {
                if (err.response) {
                    toast.error(err.response.data.message, {});
                }
                return false;
            }
        }
    }
}
