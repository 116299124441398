import {useEffect, useState} from "react";
import {ChainService} from "../../services/ChainService";
import {useParams} from "react-router";
import {OutletService} from "../../services/OutletService";
import Select from "react-select";
import {useSelector} from "react-redux";
import toast from "react-hot-toast";

const EditOutlet = (chains) => {
    const admin = useSelector((state) => state.auth);
    const {company, id} = useParams();
    const [location, setLocation] = useState('');
    const [code, setCode] = useState('');
    const [chain, setChain] = useState(null);
    const [chainOptions, setChainOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allowedPermissions, setAllowedPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/companies')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setAllowedPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!allowedPermissions.edit) {
            toast.error('You are not allowed to perform this action');
            return;
        }
        setLoading(true);
        const data = {
            location,
            code,
            chain: chain.value
        };
        const res = await OutletService.update(id, data);
        if (res) {
            e.target.reset();
        }
        setLoading(false);
    };

    const loadDetails = async () => {
        const item = await OutletService.get(id);
        if (item) {
            setLocation(item.location);
            setCode(item.code);
            setChain({label: item.chain.address, value: item.chain._id});
        }
    }

    const getChains = async () => {
        const allChains = await ChainService.chains(company);
        const _chains = [];
        for (let i = 0; i < allChains.length; i++) {
            _chains.push({label: allChains[i].address, value: allChains[i]._id});
        }
        setChainOptions(_chains);
    }


    useEffect(() => {
        loadDetails();
    }, [id]);

    useEffect(() => {
        getChains();
    }, [id])


    return (
        <>
            <div className="card mb-5">
                <div className="card-header p-3">
                    <h5 className="card-title">Update Outlet</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Outlet Location <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="location" name="location" placeholder="Outlet Location" value={location}
                                           onChange={(e) => setLocation(e.target.value)} required={true}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Outlet Code <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="code" name="code" placeholder="Outlet Code" value={code}
                                           onChange={(e) => setCode(e.target.value)} required={true}/>
                                </div>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-6">
                                <label htmlFor="type" className="form-label">Chain</label>
                                <Select
                                    name="chain"
                                    options={chainOptions}
                                    value={chain}
                                    onChange={setChain} required={true} />
                            </div>
                        </div>


                        <div className={'d-flex'}>
                            <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                            </button>
                            <button type="button" className="btn btn-secondary me-2" disabled={loading} onClick={() => {
                                window.history.back();
                            }}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Back
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </>
    );
}

export default EditOutlet;
