import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

const Base = ({children}) => {
    return <>
        <Sidebar/>
        <Header/>
        <div className="main main-app p-3 p-lg-4">
            {children}
        </div>
    </>;
}

export default Base;
