import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {ExpenseService} from "../../services/ExpenseService";

const ExpenseDetails = () => {
    const {id} = useParams();
    const [expense, setExpense] = useState(null);
    const [loading, setLoading] = useState(false);

    const loadDetails = async () => {
        const blog = await ExpenseService.get(id);
        console.log(blog);
        setExpense(blog);
    }


    useEffect(() => {
        loadDetails();
    }, [id]);

    return (
        <>
            <div className="card">
                <div className="card-header p-3">
                    <h5 className="card-title">Expense Details</h5>
                </div>
                <div className="card-body">
                    <form>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Title <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="title" name="title" placeholder="Category Title" required={true}/>
                                </div>
                            </div>

                        </div>

                        <div className={'d-flex'}>
                            <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                            </button>
                            <button type="button" className="btn btn-secondary me-2" disabled={loading} onClick={() => {
                                window.history.back();
                            }}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Back
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </>
    );
}

export default ExpenseDetails;
