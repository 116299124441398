import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net';
import {useLocation, useNavigate} from "react-router";

import baseConfig from "../../Config";
import {CompanyService} from "../../services/CompanyService";
import {ChainService} from "../../services/ChainService";
import {OutletService} from "../../services/OutletService";
import Select from "react-select";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {DateRangePicker} from 'react-date-range';
import {ExpenseService} from "../../services/ExpenseService";
import toast from "react-hot-toast";
import {ExpenseCategoryService} from "../../services/ExpenseCategory";
import {ExpenseTypeService} from "../../services/ExpenseType";
import * as moment from "moment";


const ManageVehicleReports = () => {
    const admin = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const [company, setCompany] = useState(null);
    const [chain, setChain] = useState(null);
    const [outlet, setOutlet] = useState(null);
    const [category, setCategory] = useState(null);
    const [type, setType] = useState(null);
    const [status, setStatus] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [rejectReason, setRejectReason] = useState('');
    const [chainOptions, setChainOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [outletOptions, setOutletOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [selectionRange, setSelectionRange] = useState(
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
    );
    const [expenses, setExpenses] = useState([]);
    const statusOptions = [
        {label: 'Pending', value: 'Pending'},
        {label: 'Manager Approved', value: 'Manager Approved'},
        {label: 'Manager Rejected', value: 'Manager Rejected'},
        {label: 'Operational Manager Approved', value: 'Operational Manager Approved'},
        {label: 'Operational Manager Rejected', value: 'Operational Manager Rejected'},
        {label: 'Completed', value: 'Completed'},
    ];
    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/reports') || permission.routes.includes('/reports/by-vehicle')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    const getCompanies = async () => {
        const allItems = await CompanyService.companies();
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.companies.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].name, value: allItems[i]._id});
            }
        }
        setCompanyOptions(items);
    }

    const getChains = async () => {
        const allItems = await ChainService.chains([company.value]);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.chains.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].address, value: allItems[i]._id});
            }
        }
        setChainOptions(items);
    }

    const getOutlets = async () => {
        const allItems = await OutletService.outlets([chain.value]);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.outlets.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].location, value: allItems[i]._id});
            }
        }
        setOutletOptions(items);
    }

    const getCategories = async () => {
        const allItems = await ExpenseCategoryService.categories();
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            items.push({label: allItems[i].title, value: allItems[i]._id});
        }
        setCategoryOptions(items);
    }

    const getTypes = async () => {
        const allItems = await ExpenseTypeService.types(category.value);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            items.push({label: allItems[i].title, value: allItems[i]._id});
        }
        setTypeOptions(items);
    }

    useEffect(() => {
        getCompanies();
        getCategories();
    }, []);

    useEffect(() => {
        if (company !== null) {
            getChains();
        }
    }, [company])

    useEffect(() => {
        if (chain !== null) {
            getOutlets();
        }
    }, [chain])

    useEffect(() => {
        if (category !== null) {
            getTypes();
        }
    }, [category])

    useEffect(() => {
        if (permissions.view) {
            getData();
        }
    }, [company, chain, outlet, selectionRange, category, type, status, permissions]);

    const updateStatus = async (status) => {
        console.log(expenses);
        const data = [];
        expenses.forEach((expense) => {
            if (expense.selected) {
                data.push({
                    id: expense.id,
                    status: status,
                    ...(status === 'Manager Rejected' || status === 'Operational Manager Rejected' ? {reason: rejectReason} : {})
                });
            }
        });
        const res = await ExpenseService.bulkStatusUpdate({
            expenses: data
        });
        if (res) {
            setRejectReason('');
            getData();
        }
    }

    const getData = () => {
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        let token = localStorage.getItem('token')
        $('#table').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        return `<p class="text-primary"><b>KWD ${data}</b></p>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 1
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/report/list/by-vehicle`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify({
                        ...d,
                        ...(company ? {company: company.value} : {}),
                        ...(chain ? {chain: chain.value} : {}),
                        ...(outlet ? {outlet: outlet.value} : {}),
                        ...(startDate ? {startDate: startDate} : {}),
                        ...(endDate ? {endDate: endDate} : {}),
                        ...(category ? {category: category.value} : {}),
                        ...(type ? {type: type.value} : {}),
                    });
                },
            }
        });
    }

    return (
        <div>
            <div className="card mt-3">
                <div className="card-header p-3 d-flex justify-content-between">
                    <h5 className="card-title">Filters</h5>
                    <div>
                        <button type="button" className="btn btn-secondary me-4" onClick={() => {
                            setCompany(null);
                            setChain(null);
                            setOutlet(null);
                            setCategory(null);
                            setType(null);
                            setStartDate(moment().startOf('month').toISOString());
                            setEndDate(moment().endOf('month').toISOString());
                        }}>Reset
                        </button>
                        <button className="btn btn-primary ms-2 me-2" onClick={() => {
                            window.open(encodeURI(`${baseConfig.apiBaseUrl}/report/list/by-vehicle/excel?${$.param({
                                ...(company ? {company: company.value} : {}),
                                ...(chain ? {chain: chain.value} : {}),
                                ...(outlet ? {outlet: outlet.value} : {}),
                                ...(startDate ? {startDate: startDate} : {}),
                                ...(endDate ? {endDate: endDate} : {}),
                                ...(category ? {category: category.value} : {}),
                                ...(type ? {type: type.value} : {}),
                                format: 'xlsx',
                            })}`), '_blank');
                        }}>Export Excel
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <div className={"row mb-5"}>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Companies</label>
                            <Select
                                name="chain"
                                options={companyOptions}
                                value={company}
                                onChange={setCompany}/>
                        </div>

                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Chains</label>
                            <Select
                                name="chain"
                                options={chainOptions}
                                value={chain}
                                onChange={setChain}/>
                        </div>

                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Outlets</label>
                            <Select
                                name="chain"
                                options={outletOptions}
                                value={outlet}
                                onChange={setOutlet}/>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Categories</label>
                            <Select
                                name="chain"
                                options={categoryOptions}
                                value={category}
                                onChange={setCategory}/>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Types</label>
                            <Select
                                name="chain"
                                options={typeOptions}
                                value={type}
                                onChange={setType}/>
                        </div>

                        <div className={"col-12 mt-5 d-flex justify-content-center w-100"}>
                            <DateRangePicker
                                months={2}
                                ranges={[selectionRange]}
                                direction="horizontal"
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                onChange={(ranges) => {
                                    setSelectionRange(ranges.selection);
                                    setStartDate(moment(ranges.selection.startDate).toISOString());
                                    setEndDate(moment(ranges.selection.endDate).toISOString());
                                }}
                            />
                        </div>
                    </div>


                </div>
            </div>
            <div className="card mt-3">
                <div className="card-header p-3">
                    <h5 className="card-title">Manage Vehicle Reports</h5>
                </div>
                <div className="card-body">
                    <div className='table-responsive w-100'>
                        <table
                            id="table"
                            className='table datatable w-100'
                        >
                            <thead>
                            <tr>

                                <th>Vehicle</th>
                                <th>Amount</th>
                                <th>Drivers</th>
                                <th>Categories</th>
                                <th>Types</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ManageVehicleReports;
