import Select from "react-select";
import {useEffect, useState} from "react";
import {ChainService} from "../../services/ChainService";
import {CompanyService} from "../../services/CompanyService";
import {OutletService} from "../../services/OutletService";
import {UploadService} from "../../services/UploadService";
import {EmployeeService} from "../../services/EmployeeService";
import toast from "react-hot-toast";
import {useParams} from "react-router";
import {useSelector} from "react-redux";

const Employee = () => {
    const {id} = useParams();
    const admin = useSelector((state) => state.auth);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [picture, setPicture] = useState(null);
    const [pictureUrl, setPictureUrl] = useState(null);
    const [code, setCode] = useState(null);
    const [chains, setChains] = useState([]);
    const [outlets, setOutlets] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [chainOptions, setChainOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [outletOptions, setOutletOptions] = useState([]);
    const [permissionOptions, setPermissionOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allowedPermissions, setAllowedPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/employees')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setAllowedPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!allowedPermissions.edit) {
            toast.error('You are not allowed to perform this action');
            return;
        }
        setLoading(true);
        let pictureUrl = "";
        if (picture !== null) {
            pictureUrl = await uploadFile(picture);
        }
        const data = {
            name,
            phoneNumber,
            email,
            ...((password !== null && password.length > 0) && {password}),
            profilePic: pictureUrl,
            employeeCode: code,
            chains: chains.map((chain) => chain.value),
            outlets: outlets.map((outlet) => outlet.value),
            companies: companies.map((company) => company.value),
            permissions: permissions.map((permission) => permission.value),
        };
        const res = await EmployeeService.update(id, data);
        if (res) {
            toast.success("Employee updated successfully", {});
        }
        setLoading(false);
    }

    const uploadFile = async (file) => {
        let formData = new FormData();
        formData.append("files", file);
        const urls = await UploadService.upload(formData);
        console.log(urls);
        return urls.length > 0 ? urls[0] : '';
    }

    const loadDetails = async () => {
        const item = await EmployeeService.get(id);
        console.log(item);
        if (item) {
            setName(item.name);
            setPhoneNumber(item.phoneNumber);
            setEmail(item.email);
            setCode(item.employeeCode);
            // set companies
            const companyItems = [];
            for (let i = 0; i < item.companies.length; i++) {
                companyItems.push({label: item.companies[i].name, value: item.companies[i]._id});
            }
            setCompanies(companyItems);
            // set chains
            const chainItems = [];
            for (let i = 0; i < item.chains.length; i++) {
                chainItems.push({label: item.chains[i].address, value: item.chains[i]._id});
            }
            setChains(chainItems);
            // set outlets
            const outletItems = [];
            for (let i = 0; i < item.outlets.length; i++) {
                outletItems.push({label: item.outlets[i].location, value: item.outlets[i]._id});
            }
            setOutlets(outletItems);
            // set permissions
            const permissionItems = [];
            for (let i = 0; i < item.permissions.length; i++) {
                permissionItems.push({label: item.permissions[i].title, value: item.permissions[i]._id});
            }
            setPermissions(permissionItems);
        }
    }

    const getCompanies = async () => {
        const allItems = await CompanyService.companies();
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.companies.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].name, value: allItems[i]._id});
            }
        }
        setCompanyOptions(items);
    }

    const getChains = async () => {
        const allItems = await ChainService.chains(companies.map((chain) => chain.value));
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.chains.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].address, value: allItems[i]._id});
            }
        }
        setChainOptions(items);
    }

    const getOutlets = async () => {
        const allItems = await OutletService.outlets(chains.map((chain) => chain.value));
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.outlets.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].location, value: allItems[i]._id});
            }
        }
        setOutletOptions(items);
    }

    const getPermissions = async () => {
        const allItems = await EmployeeService.permissions();
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.permissions.find((item) => item.title === 'Super Admin')) {
                items.push({label: allItems[i].title, value: allItems[i]._id});
            } else if ((admin.permissions.find((item) => item.title === 'Manager') || admin.permissions.find((item) => item.title === 'Operation Manager')) && allItems[i].title === 'Driver') {
                items.push({label: allItems[i].title, value: allItems[i]._id});
            }
        }
        setPermissionOptions(items);
    }

    useEffect(() => {
        getCompanies();
    }, []);

    useEffect(() => {
        if (companies.length > 0) {
            getChains();
        }
    }, [companies])

    useEffect(() => {
        if (chains.length > 0) {
            getOutlets();
        }
    }, [chains])

    useEffect(() => {
        getPermissions();
    }, []);

    useEffect(() => {
        loadDetails();
    }, [id])

    return (
        <>
            <div className="card mb-5">
                <div className="card-header p-3">
                    <h5 className="card-title">Edit Employee</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Name <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="name" name="location"
                                           placeholder="Employee Name" value={name}
                                           onChange={(e) => setName(e.target.value)} required={true}/>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Phone Number <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="phoneNumber" name="phoneNumber"
                                           placeholder="Employee Phone Number" value={phoneNumber}
                                           onChange={(e) => setPhoneNumber(e.target.value)} required={true}/>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Email <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="email" className="form-control" id="email" name="email"
                                           placeholder="Employee Email" value={email}
                                           onChange={(e) => setEmail(e.target.value)} required={true}/>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Code <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="code" name="code"
                                           placeholder="Employee Code" value={code}
                                           onChange={(e) => setCode(e.target.value)} required={true}/>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Password</label>
                                    <input type="text" className="form-control" id="password" name="password"
                                           placeholder="Employee Model"
                                           onChange={(e) => setPassword(e.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="image" className="form-label">Employee Picture</label>
                                <input type="file" className="form-control" id="logo" name="logo"
                                       onChange={(e) => setPicture(e.target.files[0])}/>
                            </div>

                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="type" className="form-label">Permissions <span
                                    className='text-danger'>(required)</span></label>
                                <Select
                                    name="permission"
                                    options={permissionOptions}
                                    value={permissions}
                                    isMulti={true}
                                    onChange={setPermissions} required={true}/>
                            </div>

                            {(permissions.filter((permission) => permission.label !== 'Super Admin').length > 0) && <>
                                <div className="mb-3 col-sm-12 col-md-6">
                                    <label htmlFor="type" className="form-label">Companies</label>
                                    <Select
                                        name="chain"
                                        options={companyOptions}
                                        value={companies}
                                        isMulti={true}
                                        onChange={setCompanies}/>
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6">
                                    <label htmlFor="type" className="form-label">Chains</label>
                                    <Select
                                        name="chain"
                                        options={chainOptions}
                                        value={chains}
                                        isMulti={true}
                                        onChange={setChains}/>
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6">
                                    <label htmlFor="type" className="form-label">Outlets</label>
                                    <Select
                                        name="chain"
                                        options={outletOptions}
                                        value={outlets}
                                        isMulti={true}
                                        onChange={setOutlets}/>
                                </div>
                            </>}
                        </div>

                        <div className={'d-flex'}>
                            <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                            </button>
                            <button type="button" className="btn btn-secondary me-2" disabled={loading} onClick={() => {
                                window.history.back();
                            }}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Back
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </>
    );
}

export default Employee;
