import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net';
import { useLocation, useNavigate } from "react-router";

import baseConfig from "../../Config";
import {CompanyService} from "../../services/CompanyService";
import {ExpenseTypeService} from "../../services/ExpenseType";
import {PenaltyService} from "../../services/PenaltyService";
import Modal from "react-bootstrap/Modal";
import ManageExpenseHistory from "../expense/ManageExpenseHistory";
import ManagePenaltyHistory from "./ManagePenaltyHistory";
import toast from "react-hot-toast";


const ManagePenalties = () => {
    const admin = useSelector((state) => state.auth);
    const [penaltyId, setPenaltyId] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/penalties')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    useEffect(() => {
        if (permissions.view) {
            getData();
        }
    }, [permissions]);

    useEffect(() => {
        if (penaltyId) {
            $('#penaltyModal').addClass('show');
            $('#penaltyModal').css('display', 'block');
        }
    }, [penaltyId]);

    const getData = () => {
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        let token = localStorage.getItem('token')
        $('#table').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            if (!permissions.view) {
                                toast.error('You do not have permission to perform this action');
                                return;
                            }
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 6
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger">Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            if (!permissions.delete) {
                                toast.error('You do not have permission to perform this action');
                                return;
                            }
                            const data = {
                                deleted: true
                            }
                            await PenaltyService.update(rowData[7], data);
                            getData();
                        })
                    },
                    "targets": 7
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">History</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                           setPenaltyId(cellData);
                        })
                    },
                    "targets": 8
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/penalty/list`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify(d);
                },
            }
        });
    }

    return (
        <>
            <div>

                <div className="card mt-3">
                    <div className="card-header d-flex justify-content-between p-3">
                        <h5 className="card-title">Manage Penalties</h5>
                        <div className="card-tools">
                            {permissions.add && <button type="button" className="btn btn-secondary"
                                                        onClick={() => navigate(`${location.pathname}/add`)}>Add
                                Penalty</button>}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='table-responsive w-100'>
                            <table
                                id="table"
                                className='table datatable w-100'
                            >
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Register Number</th>
                                    <th>Driver</th>
                                    <th>Vehicle</th>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                    <th>History</th>
                                </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>
            </div>
            <div
                className="modal modal-xl" id="penaltyModal" tabIndex="-1" aria-labelledby="penaltyModalLabel"
                aria-hidden="true"
                style={{display: 'none'}}
            >
                <Modal.Dialog className="w-100">
                    <Modal.Header>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => {
                                    $('#penaltyModal').removeClass('show');
                                    $('#penaltyModal').css('display', 'none');
                                    setPenaltyId(null);
                                }}></button>
                    </Modal.Header>

                    <Modal.Body className="w-100">
                        {penaltyId && <ManagePenaltyHistory id={penaltyId}/>}
                    </Modal.Body>
                </Modal.Dialog>
            </div>
        </>
    );
}

export default ManagePenalties;
