import {useEffect, useState} from "react";
import {ExpenseTypeService} from "../../services/ExpenseType";
import {ChainService} from "../../services/ChainService";
import {ExpenseCategoryService} from "../../services/ExpenseCategory";
import Select from "react-select";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import toast from "react-hot-toast";

const EditExpenseType = () => {
    const admin = useSelector((state) => state.auth);
    const {id} = useParams();
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState(null);
    const [distanceRequired, setDistanceRequired] = useState(null);
    const [loading, setLoading] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const distanceRequiredOptions = [
        {value: false, label: 'No'},
        {value: true, label: 'Yes'},
    ];
    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/expense-types')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    const loadDetails = async () => {
        const blog = await ExpenseTypeService.get(id);
        console.log(blog);
        if (blog) {
            setTitle(blog.title);
            setCategory({label: blog.category.title, value: blog.category._id});
            setDistanceRequired({label: blog.distanceRequired ? 'Yes' : 'No', value: blog.distanceRequired});
        }
    }

    const getCategories = async () => {
        const allCategories = await ExpenseCategoryService.categories();
        const _chains = [];
        for (let i = 0; i < allCategories.length; i++) {
            _chains.push({label: allCategories[i].title, value: allCategories[i]._id});
        }
        setCategoryOptions(_chains);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!permissions.edit) {
            toast.error('You are not allowed to perform this action');
            return;
        }
        setLoading(true);
        const data = {
            title,
            category: category.value,
            distanceRequired: distanceRequired.value,
        };
        const res = await ExpenseTypeService.update(id, data);
        if (res) {
            e.target.reset();
        }
        setLoading(false);
    };

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        loadDetails();
    }, [id]);

    return (
        <>
            <div className="card">
                <div className="card-header p-3">
                    <h5 className="card-title">Edit Expense Type</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Title <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="title" name="title" placeholder="Type Title" value={title}
                                           onChange={(e) => setTitle(e.target.value)} required={true}/>
                                </div>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="type" className="form-label">Category</label>
                                <Select
                                    name="category"
                                    options={categoryOptions}
                                    value={category}
                                    onChange={setCategory} required={true} />
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="type" className="form-label">Distance Required</label>
                                <Select
                                    name="distance-required"
                                    options={distanceRequiredOptions}
                                    value={distanceRequired}
                                    onChange={setDistanceRequired} required={true} />
                            </div>

                        </div>

                        <div className={'d-flex'}>
                            <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                            </button>
                            <button type="button" className="btn btn-secondary me-2" disabled={loading} onClick={() => {
                                window.history.back();
                            }}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Back
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </>
    );
}

export default EditExpenseType;
