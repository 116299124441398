import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import PerfectScrollbar from "perfect-scrollbar";
import {useSelector} from "react-redux";

const Sidebar = () => {
    const admin = useSelector((state) => state.auth);
    const [currentPath, setCurrentPath] = React.useState('/');

    useEffect(() => {
        const psSidebar = new PerfectScrollbar('#sidebarMenu', {
            suppressScrollX: true
        });

        $('.sidebar .nav-label').on('click', function (e) {
            e.preventDefault();

            var target = $(this).next('.nav-sidebar');
            $(target).slideToggle(function () {
                psSidebar.update();
            });

        });

        $('.sidebar .has-sub').on('click', function (e) {
            e.preventDefault();

            var target = $(this).next('.nav-sub');
            $(target).slideToggle(function () {
                psSidebar.update();
            });

            var siblings = $(this).closest('.nav-item').siblings();
            siblings.each(function () {
                var nav = $(this).find('.nav-sub');
                if (nav.is(':visible')) {
                    nav.slideUp();
                }
            });
        });

    }, []);

    useEffect(() => {
        setCurrentPath(window.location.pathname);
        console.count(window.location.pathname);
    }, [window.location.pathname]);

    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <Link to={'/'} className="sidebar-logo">VMS.</Link>
            </div>
            <div id="sidebarMenu" className="sidebar-body">
                <div className="nav-group show">
                    <a href="#" className="nav-label">Dashboard</a>
                    {
                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c1') || permission.routes.includes('/dashboard/c2') || permission.routes.includes('/dashboard/c3') || permission.routes.includes('/dashboard/c4') || permission.routes.includes('/dashboard/c5') || permission.routes.includes('/dashboard/c6') || permission.routes.includes('/dashboard/c7') || permission.routes.includes('/dashboard/c8') || permission.routes.includes('/dashboard/c9') || permission.routes.includes('/dashboard/c10') || permission.routes.includes('/dashboard/c11') || permission.routes.includes('/dashboard/c12') || permission.routes.includes('/dashboard/c13') || permission.routes.includes('/dashboard/c14') || permission.routes.includes('/dashboard/c15') || permission.routes.includes('/dashboard/c16') || permission.routes.includes('/dashboard/c17')).length > 0 &&
                        <ul className="nav nav-sidebar">
                            <li className={`nav-item m-2 ${currentPath === '/' ? 'active' : ''}`}>
                                <Link to={'/'} className="nav-link"><i className="ri-bar-chart-2-line"></i>
                                    <span>Dashboard</span></Link>
                            </li>
                        </ul>
                    }
                    <a href="#" className="nav-label">Entity Management</a>
                    {
                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/companies')).length > 0 &&
                        <ul className="nav nav-sidebar">
                            <li className={`nav-item`}>
                                <a href="#" className="nav-link has-sub"><i className="ri-building-2-line"></i> <span>Company Management</span></a>
                                <nav className="nav nav-sub">
                                    <Link to={'/companies'}
                                          className={`nav-sub-link ${currentPath === '/companies' ? 'active' : ''}`}>All
                                        Companies</Link>
                                </nav>
                            </li>
                        </ul>
                    }
                    <a href="#" className="nav-label">Resource Management</a>
                    <ul className="nav nav-sidebar">
                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/vehicles')).length > 0 &&
                            <li className="nav-item">
                                <a href="#" className="nav-link has-sub"><i className="ri-bus-2-line"></i> <span>Vehicle Management</span></a>
                                <nav className="nav nav-sub">
                                    <Link to={'/vehicles'}
                                          className={`nav-sub-link ${currentPath === '/vehicles' ? 'active' : ''}`}>All
                                        Vehicles</Link>
                                </nav>
                            </li>
                        }

                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/employees')).length > 0 &&
                            <li className="nav-item">
                                <a href="#" className="nav-link has-sub"><i className="ri-user-3-line"></i> <span>Employee Management</span></a>
                                <nav className="nav nav-sub">
                                    <Link to={'/employees'}
                                          className={`nav-sub-link ${currentPath === '/employees' ? 'active' : ''}`}>All
                                        Employees</Link>
                                </nav>
                            </li>
                        }
                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/vehicle-assignments')).length > 0 &&
                            <li className="nav-item">
                                <a href="#" className="nav-link has-sub"><i className="ri-profile-line"></i> <span>Vehicle Assignment</span></a>
                                <nav className="nav nav-sub">
                                    <Link to={'/vehicle-assignments'}
                                          className={`nav-sub-link ${currentPath === '/vehicle-assignments' ? 'active' : ''}`}>All
                                        Assignments</Link>
                                </nav>
                            </li>
                        }
                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/permissions')).length > 0 &&
                            <li className="nav-item">
                                <a href="#" className="nav-link has-sub"><i className="ri-safe-2-line"></i> <span>Permission Assignment</span></a>
                                <nav className="nav nav-sub">
                                    <Link to={'/permissions'}
                                          className={`nav-sub-link ${currentPath === '/permissions' ? 'active' : ''}`}>All
                                        Permissions</Link>
                                </nav>
                            </li>
                        }
                    </ul>
                    <a href="#" className="nav-label">Expense Management</a>
                    <ul className="nav nav-sidebar">
                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/expense-categories')).length > 0 &&
                            <li className="nav-item">
                                <a href="#" className="nav-link has-sub"><i className="ri-dropbox-line"></i> <span>Category Management</span></a>
                                <nav className="nav nav-sub">
                                    <Link to={'/expense-categories'}
                                          className={`nav-sub-link ${currentPath === '/expense-categories' ? 'active' : ''}`}>All
                                        Categories</Link>
                                </nav>
                            </li>
                        }
                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/expense-types')).length > 0 &&
                            <li className="nav-item">
                                <a href="#" className="nav-link has-sub"><i className="ri-compasses-2-line"></i> <span>Type Management</span></a>
                                <nav className="nav nav-sub">
                                    <Link to={'/expense-types'}
                                          className={`nav-sub-link ${currentPath === '/expense-types' ? 'active' : ''}`}>All
                                        Types</Link>
                                </nav>
                            </li>
                        }
                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/penalty-types')).length > 0 &&
                            <li className="nav-item">
                                <a href="#" className="nav-link has-sub"><i className="ri-profile-line"></i> <span>Penalty Type Management</span></a>
                                <nav className="nav nav-sub">
                                    <Link to={'/penalty-types'}
                                          className={`nav-sub-link ${currentPath === '/penalty-types' ? 'active' : ''}`}>All
                                        Penalty Types</Link>
                                </nav>
                            </li>
                        }
                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/expenses')).length > 0 &&
                            <li className="nav-item">
                                <a href="#" className="nav-link has-sub"><i className="ri-exchange-dollar-line"></i>
                                    <span>Expense Management</span></a>
                                <nav className="nav nav-sub">
                                    <Link to={'/expenses'}
                                          className={`nav-sub-link ${currentPath === '/expenses' ? 'active' : ''}`}>All
                                        Expenses</Link>
                                </nav>
                            </li>
                        }
                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/penalties')).length > 0 &&
                            <li className="nav-item">
                                <a href="#" className="nav-link has-sub"><i className="ri-wallet-2-line"></i> <span>Penalty Management</span></a>
                                <nav className="nav nav-sub">
                                    <Link to={'/penalties'}
                                          className={`nav-sub-link ${currentPath === '/penalties' ? 'active' : ''}`}>All
                                        Penalties</Link>
                                </nav>
                            </li>
                        }
                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/reports') || permission.routes.includes('/reports/by-driver') || permission.routes.includes('/reports/by-vehicle') || permission.routes.includes('/reports/penalty')).length > 0 &&
                            <li className="nav-item">
                                <a href="#" className="nav-link has-sub"><i className="ri-file-pdf-line"></i> <span>Report Management</span></a>
                                <nav className="nav nav-sub">
                                    {
                                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/reports') || permission.routes.includes('/reports/by-driver')).length > 0 &&
                                        <Link to={'/reports/by-driver'}
                                              className={`nav-sub-link ${currentPath === '/reports/by-driver' ? 'active' : ''}`}>All
                                            Reports</Link>
                                    }
                                    {
                                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/reports') || permission.routes.includes('/reports/by-vehicle')).length > 0 &&
                                        <Link to={'/reports/by-vehicle'}
                                              className={`nav-sub-link ${currentPath === '/reports/by-vehicle' ? 'active' : ''}`}>Vehicle
                                            Reports</Link>
                                    }
                                    {
                                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/reports') || permission.routes.includes('/reports/penalty')).length > 0 &&
                                        <Link to={'/reports/penalty'}
                                              className={`nav-sub-link ${currentPath === '/reports/penalty' ? 'active' : ''}`}>Penalty
                                            Reports</Link>
                                    }
                                </nav>
                            </li>
                        }

                    </ul>
                </div>
            </div>

        </div>

    );
};

export default Sidebar;
