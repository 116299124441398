import baseConfig from "../Config";
import axios from "axios";

export class UploadService {
    static upload = async (body) => {
        let token = localStorage.getItem('token')
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": token,
                    "enctype": "multipart/form-data"
                },
            };
            try {
                let res = await axios.post(
                    `${baseConfig.apiCoreBaseUrl}/upload`,
                    body,
                    config
                );
                return res.data.data;
            } catch (e) {

            }

        }

    }

}
