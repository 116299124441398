import {useState} from "react";
import {AuthService} from "../../services/AuthService";
import toast from "react-hot-toast";

const RequestAccountDeletion = () => {
    const [service] = useState(new AuthService());
    const [email, setEmail] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        await service.requestAccountDeletion(email);
        toast.success("Account deletion request sent successfully. We will process your request and will let you know once done.", {});
        setEmail("");
    }

    return (
        <div className={'page-sign'} style={{backgroundImage: 'url("/assets/img/auth-bg.jpg")', backgroundSize: "cover"}}>
            <div className="card card-sign">
                <div className="card-header">
                    <a href="../" className="header-logo mb-4">VMS</a>
                    <h3 className="card-title">Request Account Deletion</h3>
                    <p className="card-text">Please enter your details to request account deletion.</p>
                </div>
                <form className="card-body" onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="form-label">Email address</label>
                        <input type="text" className="form-control" placeholder="Enter your email addres" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>

                    <button className="btn btn-primary btn-sign">Submit</button>

                </form>
            </div>
        </div>
    );
}

export default RequestAccountDeletion;
