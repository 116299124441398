import {useEffect, useState} from "react";
import Select from "react-select";
import {EmployeeService} from "../../services/EmployeeService";
import {VehicleService} from "../../services/VehicleService";
import {useSelector} from "react-redux";
import {PenaltyService} from "../../services/PenaltyService";
import {PenaltyTypeService} from "../../services/PenaltyType";
import toast from "react-hot-toast";

const AddPenalty = () => {
    const admin = useSelector((state) => state.auth);
    const [vehicle, setVehicle] = useState(null);
    const [driver, setDriver] = useState(null);
    const [company, setCompany] = useState(null);
    const [chain, setChain] = useState(null);
    const [outlet, setOutlet] = useState(null);
    const [amount, setAmount] = useState(0);
    const [date, setDate] = useState(null);
    const [registerNumber, setRegisterNumber] = useState('');
    const [type, setType] = useState(null);
    const [driverOptions, setDriverOptions] = useState([]);
    const [vehicleOptions, setVehicleOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [typeOptions, setTypeOptions] = useState([]);
    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/penalties')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    const getTypes = async () => {
        const items = await PenaltyTypeService.types();
        const _types = [];
        for (let i = 0; i < items.length; i++) {
            _types.push({label: items[i].title, value: items[i]._id});
        }
        setTypeOptions(_types);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!permissions.add) {
            toast.error('You are not allowed to perform this action');
            return;
        }
        setLoading(true);
        const data = {
            vehicle: vehicle.value,
            amount,
            date,
            registerNumber,
            type: type.value,
        };
        const res = await PenaltyService.add(data);
        if (res) {
            e.target.reset();
            setVehicle(null);
            setDriver(null);
            setAmount(0);
            setDate(null);
            setRegisterNumber('');
            setType(null);

        }
        setLoading(false);
    };

    const getDrivers = async () => {
        const data = {
            companies: [company.value],
            ...(chain && {chains: [chain.value]}),
            ...(outlet && {outlets: [outlet.value]}),
        };
        const allItems = await EmployeeService.employees(data);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            items.push({label: allItems[i].name, value: allItems[i]._id});
        }
        setDriverOptions(items);
    }

    const getVehicles = async () => {
        const data = {
            companies: ['all'],
        };
        const allItems = await VehicleService.vehicles(data);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            items.push({label: allItems[i].number, value: allItems[i]._id});
        }
        setVehicleOptions(items);
    }

    useEffect(() => {
        getTypes();
        getVehicles();
    }, [])


    return (
        <>
            <div className="card">
                <div className="card-header p-3">
                    <h5 className="card-title">Add Penalty</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="type" className="form-label">Vehicle <span
                                    className='text-danger'>(required)</span></label>
                                <Select
                                    name="chain"
                                    options={vehicleOptions}
                                    value={vehicle}
                                    onChange={setVehicle} required={true}/>
                            </div>

                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Amount <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="number" className="form-control" id="amount" name="amount"
                                           placeholder="Amount" value={amount}
                                           onChange={(e) => setAmount(parseFloat(e.target.value))} required={true}/>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Date <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="date" className="form-control" id="date" name="date" placeholder="Date"
                                           value={date}
                                           onChange={(e) => setDate(e.target.value)} required={true}/>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Register Number <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="register-number"
                                           name="register-number" placeholder="Register Number"
                                           value={registerNumber}
                                           onChange={(e) => setRegisterNumber(e.target.value)} required={true}/>
                                </div>
                            </div>

                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="type" className="form-label">Type <span
                                    className='text-danger'>(required)</span></label>
                                <Select
                                    name="chain"
                                    options={typeOptions}
                                    value={type}
                                    onChange={setType} required={true}/>
                            </div>

                        </div>

                        <div className={'d-flex'}>
                            <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                            </button>
                            <button type="button" className="btn btn-secondary me-2" disabled={loading} onClick={() => {
                                window.history.back();
                            }}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Back
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </>);
}

export default AddPenalty;
