import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router";
import React, {useEffect, useState} from "react";
import {CompanyService} from "../../services/CompanyService";
import {ChainService} from "../../services/ChainService";
import {OutletService} from "../../services/OutletService";
import {ExpenseCategoryService} from "../../services/ExpenseCategory";
import {ExpenseTypeService} from "../../services/ExpenseType";
import Select from "react-select";
import {DateRangePicker} from "react-date-range";
import * as moment from "moment/moment";
import ReactApexChart from "react-apexcharts";
import {DashboardService} from "../../services/Dashboard";
import {EmployeeService} from "../../services/EmployeeService";
import {VehicleService} from "../../services/VehicleService";
import {Link} from "react-router-dom";


const Dashboard = () => {
    const admin = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const [companies, setCompanies] = useState([]);
    const [chains, setChains] = useState([]);
    const [outlets, setOutlets] = useState([]);
    const [categories, setCategories] = useState([]);
    const [types, setTypes] = useState([]);
    const [statuses, setStatuses] = useState([
        {label: 'Pending', value: 'Pending'},
        {label: 'Manager Approved', value: 'Manager Approved'},
        {label: 'Operational Manager Approved', value: 'Operational Manager Approved'},
        {label: 'Completed', value: 'Completed'},
    ]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [rejectReason, setRejectReason] = useState('');
    const [chainOptions, setChainOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [outletOptions, setOutletOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [driverOptions, setDriverOptions] = useState([]);
    const [vehicleOptions, setVehicleOptions] = useState([]);
    const [selectionRange, setSelectionRange] = useState(
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
    );
    const [showFilters, setShowFilters] = useState(false);
    const [showDateFilter, setShowDateFilter] = useState(false);
    const statusOptions = [
        {label: 'Pending', value: 'Pending'},
        {label: 'Manager Approved', value: 'Manager Approved'},
        {label: 'Manager Rejected', value: 'Manager Rejected'},
        {label: 'Operational Manager Approved', value: 'Operational Manager Approved'},
        {label: 'Operational Manager Rejected', value: 'Operational Manager Rejected'},
        {label: 'Completed', value: 'Completed'},
    ];
    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c1') || permission.routes.includes('/dashboard/c2') || permission.routes.includes('/dashboard/c3') || permission.routes.includes('/dashboard/c4') || permission.routes.includes('/dashboard/c5') || permission.routes.includes('/dashboard/c6') || permission.routes.includes('/dashboard/c7') || permission.routes.includes('/dashboard/c8') || permission.routes.includes('/dashboard/c9') || permission.routes.includes('/dashboard/c10') || permission.routes.includes('/dashboard/c11') || permission.routes.includes('/dashboard/c12') || permission.routes.includes('/dashboard/c13') || permission.routes.includes('/dashboard/c14') || permission.routes.includes('/dashboard/c15') || permission.routes.includes('/dashboard/c16') || permission.routes.includes('/dashboard/c17')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    const getCompanies = async () => {
        const allItems = await CompanyService.companies();
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.companies.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].name, value: allItems[i]._id});
            }
        }
        setCompanyOptions(items);
    }

    const getChains = async () => {
        const allItems = await ChainService.chains(companies.map((i) => i.value));
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.chains.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].address, value: allItems[i]._id});
            }
        }
        setChainOptions(items);
    }

    const getOutlets = async () => {
        let _chains = [];
        if (chains.length > 0) {
            _chains = chains.map((i) => i.value);
        } else {
            _chains = ['all'];
        }
        const allItems = await OutletService.outlets(_chains);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.outlets.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].location, value: allItems[i]._id});
            }
        }
        setOutletOptions(items);
    }

    const getCategories = async () => {
        const allItems = await ExpenseCategoryService.categories();
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            items.push({label: allItems[i].title, value: allItems[i]._id});
        }
        setCategoryOptions(items);
    }

    const getTypes = async () => {
        const items = [];
        for (let i = 0; i < categories.length; i++) {
            const allItems = await ExpenseTypeService.types([categories[i].value]);
            for (let i = 0; i < allItems.length; i++) {
                items.push({label: allItems[i].title, value: allItems[i]._id});
            }
        }
        if (categories.length === 0) {
            const allItems = await ExpenseTypeService.types('all');
            for (let i = 0; i < allItems.length; i++) {
                items.push({label: allItems[i].title, value: allItems[i]._id});
            }
        }
        setTypeOptions(items);
    }

    const getDrivers = async () => {
        const allCompanies = companies.map((i) => i.value);
        const data = {
            companies: companies.length > 0 ? allCompanies : ['all'],
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
        };
        const allItems = await EmployeeService.employees(data);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            items.push({label: allItems[i].name, value: allItems[i]._id});
        }
        setDriverOptions(items);
    }

    const getVehicles = async () => {
        const allCompanies = companies.map((i) => i.value);
        const data = {
            companies: companies.length > 0 ? allCompanies : ['all'],
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
        };
        const allItems = await VehicleService.vehicles(data);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            items.push({label: allItems[i].number, value: allItems[i]._id});
        }
        setVehicleOptions(items);
    }

    useEffect(() => {
        getCompanies();
        getCategories();
        getTypes();
    }, []);

    useEffect(() => {
        if (companies.length > 0) {
            getChains();
        }
    }, [companies])

    useEffect(() => {
        getOutlets();
    }, [chains, categories])

    useEffect(() => {
        getTypes();
    }, [categories])

    useEffect(() => {
        getVehicles();
        getDrivers();
    }, [chains, companies, outlets])

    useEffect(() => {
        getData();
    }, [companies, chains, outlets, selectionRange, categories, types, statuses]);

    // Expense By Categories
    const [vehicleExpenseByCategory, setVehicleExpenseByCategory] = useState({
        series: [{
            data: []
        }],
        options: {
            chart: {
                height: 350,
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '10%',
                    distributed: true,
                }
            },

            xaxis: {
                categories: [],
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            }
        },


    });
    const [vehicleExpenseByCategoryList, setVehicleExpenseByCategoryList] = useState([]);
    const [vehicleExpenseByCategoryView, setVehicleExpenseByCategoryView] = useState(null);

    // Average Expense By Month
    const [vehicleAverageExpenseByMonth, setVehicleAverageExpenseByMonth] = useState({
        series: [{
            data: []
        }],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '10%',
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: [],
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            }
        },


    });
    const [vehicleAverageExpenseByMonthList, setVehicleAverageExpenseByMonthList] = useState([]);
    const [vehicleAverageExpenseByMonthView, setVehicleAverageExpenseByMonthView] = useState(null);

    // Daily Expense
    const [dailyExpense, setDailyExpense] = useState({
        series: [{
            data: []
        }],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '10%',
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: [],
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            }
        },


    });
    const [dailyExpenseList, setDailyExpenseList] = useState([]);
    const [dailyExpenseView, setDailyExpenseView] = useState(null);

    // Expense By Company
    const [vehicleExpenseByCompany, setVehicleExpenseByCompany] = useState({
        series: [{
            data: []
        }],
        options: {
            chart: {
                height: 350,
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '10%',
                    distributed: true,
                }
            },

            xaxis: {
                categories: [],
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            }
        },


    });
    const [vehicleExpenseByCompanyList, setVehicleExpenseByCompanyList] = useState([]);
    const [vehicleExpenseByCompanyView, setVehicleExpenseByCompanyView] = useState(null);

    // Expense By Chain
    const [vehicleExpenseByChain, setVehicleExpenseByChain] = useState({
        series: [{
            data: []
        }],
        options: {
            chart: {
                height: 350,
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '10%',
                    distributed: true,
                }
            },

            xaxis: {
                categories: [],
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            }
        },


    });
    const [vehicleExpenseByChainList, setVehicleExpenseByChainList] = useState([]);
    const [vehicleExpenseByChainView, setVehicleExpenseByChainView] = useState(null);

    // Expense By Outlet
    const [vehicleExpenseByOutlets, setVehicleExpenseByOutlets] = useState({
        series: [],
        options: {
            chart: {
                height: 350,
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '10%',
                    distributed: true,
                }
            },

            xaxis: {
                categories: [],
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            }
        },


    });
    const [vehicleExpenseByOutletList, setVehicleExpenseByOutletList] = useState([]);
    const [vehicleExpenseByOutletView, setVehicleExpenseByOutletView] = useState(null);

    // Expense By Purpose
    const [vehicleExpenseByPurpose, setVehicleExpenseByPurpose] = useState({
        series: [],
        options: {
            chart: {
                height: 350,
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '10%',
                    distributed: true,
                }
            },

            xaxis: {
                categories: [],
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            }
        },


    });
    const [vehicleExpenseByPurposeList, setVehicleExpenseByPurposeList] = useState([]);
    const [vehicleExpenseByPurposeView, setVehicleExpenseByPurposeView] = useState(null);

    // Penalty by type
    const [penaltyByType, setPenaltyByType] = useState({
        series: [{
            data: []
        }],
        options: {
            chart: {
                height: 350,
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '10%',
                    distributed: true,
                }
            },

            xaxis: {
                categories: [],
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            }
        },


    });
    const [penaltyByTypeList, setPenaltyByTypeList] = useState([]);
    const [penaltyByTypeView, setPenaltyByTypeView] = useState(null);

    // Penalty by chain
    const [penaltyByChain, setPenaltyByChain] = useState({
        series: [{
            data: []
        }],
        options: {
            chart: {
                height: 350,
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '10%',
                    distributed: true,
                }
            },

            xaxis: {
                categories: [],
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            }
        },


    });
    const [penaltyByChainList, setPenaltyByChainList] = useState([]);
    const [penaltyByChainView, setPenaltyByChainView] = useState(null);

    // Penalty by driver
    const [penaltyByDriver, setPenaltyByDriver] = useState({
        series: [{
            data: []
        }],
        options: {
            chart: {
                height: 350,
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '10%',
                    distributed: true,
                }
            },

            xaxis: {
                categories: [],
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            }
        },


    });
    const [penaltyByDriverList, setPenaltyByDriverList] = useState([]);
    const [penaltyByDriverView, setPenaltyByDriverView] = useState(null);

    // Penalty by vehicle
    const [penaltyByVehicle, setPenaltyByVehicle] = useState({
        series: [{
            data: []
        }],
        options: {
            chart: {
                height: 350,
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '10%',
                    distributed: true,
                }
            },

            xaxis: {
                categories: [],
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            }
        },


    });
    const [penaltyByVehicleList, setPenaltyByVehicleList] = useState([]);
    const [penaltyByVehicleView, setPenaltyByVehicleView] = useState(null);

    // Top Expensive Vehicles
    const [topExpensiveVehicle, setTopExpensiveVehicle] = useState([]);

    // Top Expensive Drivers
    const [topExpensiveDriver, setTopExpensiveDriver] = useState([]);

    // Vehicle Expense By Categories
    const [vehicleExpenseByCategories, setVehicleExpenseByCategories] = useState([]);

    // Vehicle Expense By Type
    const [vehicleExpenseByTypes, setVehicleExpenseByTypes] = useState([]);

    // Outlet Expense By Vehicle
    const [outletExpenseByVehicles, setOutletExpenseByVehicles] = useState([]);

    // Vehicle Exepense By Outlet
    const [vehicleExpenseByOutlet, setVehicleExpenseByOutlet] = useState([]);

    const getExpenseByCategories = async () => {
        const res = await DashboardService.expenseByCategories({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            const series = [];
            const categories = [];
            for (let i = 0; i < res.length; i++) {
                series.push(res[i].totalAmount);
                categories.push(res[i].category);
            }
            setVehicleExpenseByCategoryList(res);
            setVehicleExpenseByCategory({
                series: vehicleExpenseByCategoryView === 'pie' || vehicleExpenseByCategoryView === 'donut' ? series : [{
                    data: series,
                }],
                options: {
                    chart: {
                        height: 350,
                        type: vehicleExpenseByCategoryView,
                    },
                    labels: categories,
                    plotOptions: {
                        bar: {
                            columnWidth: '10%',
                            distributed: true,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return `KWD ${val}`;
                                }
                            }
                        }
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: categories,
                        labels: {
                            style: {
                                fontSize: '12px'
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return `KWD ${val}`;
                            }
                        }
                    }
                },
            });
        }
        console.log(res);
    };

    const getAverageExpenseByMonth = async () => {
        const res = await DashboardService.averageExpenseByMonth({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            const series = [];
            const categories = [];
            for (let i = 0; i < res.length; i++) {
                series.push(Math.round(res[i].averageExpense));
                categories.push(res[i].vehicleDetails.number);
            }
            setVehicleAverageExpenseByMonthList(res);
            setVehicleAverageExpenseByMonth({
                series: vehicleAverageExpenseByMonthView === 'pie' || vehicleAverageExpenseByMonthView === 'donut' ? series : [{
                    data: series,
                }],
                options: {
                    chart: {
                        height: 350,
                        type: vehicleAverageExpenseByMonthView,

                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '10%',
                            distributed: true,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return `KWD ${val}`;
                                }
                            }
                        }
                    },
                    labels: categories,
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: categories,
                        labels: {
                            style: {
                                fontSize: '12px'
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return `KWD ${val}`;
                            }
                        }
                    }
                },
            });
        }
        console.log(res);
    };
    const getDailyExpense = async () => {
        const res = await DashboardService.dailyVehicleExpense({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            const series = [];
            const categories = [];
            for (let i = 0; i < res.length; i++) {
                series.push(res[i].totalExpense);
                categories.push(res[i].date);
            }
            setDailyExpenseList(res);
            setDailyExpense({
                series: dailyExpenseView === 'pie' || dailyExpenseView === 'donut' ? series : [{
                    data: series,
                }],
                options: {
                    chart: {
                        height: 350,
                        type: dailyExpenseView,
                        events: {
                            click: function (chart, w, e) {
                                // console.log(chart, w, e)
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '10%',
                            distributed: true,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return `KWD ${val}`;
                                }
                            }
                        }
                    },
                    labels: categories,
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: categories,
                        labels: {
                            style: {
                                fontSize: '12px'
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return `KWD ${val}`;
                            }
                        }
                    }
                },
            });
        }
        console.log(res);
    };

    const getExpenseByCompanies = async () => {
        const res = await DashboardService.getTotalExpenseByCompany({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            const series = [];
            const companies = [];
            for (let i = 0; i < res.length; i++) {
                series.push(res[i].totalExpense);
                companies.push(res[i].companyDetails.name);
            }
            setVehicleExpenseByCompanyList(res);
            setVehicleExpenseByCompany({
                series: (vehicleExpenseByCompanyView === 'pie' || vehicleExpenseByCompanyView === 'donut') ? series : [{
                    data: series,
                }],
                options: {
                    chart: {
                        height: 350,
                        ...((vehicleExpenseByCompanyView === 'pie' || vehicleExpenseByCompanyView === 'donut') && {width: 380}),
                        type: vehicleExpenseByCompanyView,
                    },
                    labels: companies,
                    plotOptions: {
                        bar: {
                            columnWidth: '10%',
                            distributed: true,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return `KWD ${val}`;
                                }
                            }
                        }
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: companies,
                        labels: {
                            style: {
                                fontSize: '12px'
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return `KWD ${val}`;
                            }
                        }
                    }
                },
            });
        }
        console.log(res);
    };

    const getExpenseByChains = async () => {
        const res = await DashboardService.getTotalExpenseByChain({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            const series = [];
            const chains = [];
            for (let i = 0; i < res.length; i++) {
                series.push(res[i].totalExpense);
                chains.push(res[i].chainDetails.address);
            }
            setVehicleExpenseByChainList(res);
            setVehicleExpenseByChain({
                series: (vehicleExpenseByChainView === 'pie' || vehicleExpenseByChainView === 'donut') ? series : [{
                    data: series,
                }],
                options: {
                    chart: {
                        height: 350,
                        ...((vehicleExpenseByChainView === 'pie' || vehicleExpenseByChainView === 'donut') && {width: 380}),
                        type: vehicleExpenseByChainView,
                    },
                    labels: chains,
                    plotOptions: {
                        bar: {
                            columnWidth: '10%',
                            distributed: true,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return `KWD ${val}`;
                                }
                            }
                        }
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: chains,
                        labels: {
                            style: {
                                fontSize: '12px'
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return `KWD ${val}`;
                            }
                        }
                    }
                },
            });
        }
        console.log(res);
    };

    const getExpenseByOutlets = async () => {
        const res = await DashboardService.getTotalExpenseByOutlet({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            const series = [];
            const outlets = [];
            for (let i = 0; i < res.length; i++) {
                series.push(res[i].totalExpense);
                outlets.push(res[i].outletDetails.location);
            }
            setVehicleExpenseByOutletList(res);
            setVehicleExpenseByOutlets({
                series: (vehicleExpenseByOutletView === 'pie' || vehicleExpenseByOutletView === 'donut') ? series : [{
                    data: series,
                }],
                options: {
                    chart: {
                        height: 350,
                        ...((vehicleExpenseByOutletView === 'pie' || vehicleExpenseByOutletView === 'donut') && {width: 380}),
                        type: vehicleExpenseByOutletView,
                    },
                    labels: outlets,
                    plotOptions: {
                        bar: {
                            columnWidth: '10%',
                            distributed: true,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return `KWD ${val}`;
                                }
                            }
                        }
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: outlets,
                        labels: {
                            style: {
                                fontSize: '12px'
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return `KWD ${val}`;
                            }
                        }
                    }
                },
            });
        }
        console.log(res);
    };

    const getExpenseByPurpose = async () => {
        const res = await DashboardService.getTotalVehicleExpenseByPurpose({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            const series = [];
            const outlets = [];
            for (let i = 0; i < res.length; i++) {
                series.push(res[i].totalExpense);
                outlets.push(res[i].purpose ?? 'Default');
            }
            setVehicleExpenseByPurposeList(res);
            setVehicleExpenseByPurpose({
                series: (vehicleExpenseByPurposeView === 'pie' || vehicleExpenseByPurposeView === 'donut') ? series : [{
                    data: series,
                }],
                options: {
                    chart: {
                        height: 350,
                        ...((vehicleExpenseByPurposeView === 'pie' || vehicleExpenseByPurposeView === 'donut') && {width: 380}),
                        type: vehicleExpenseByPurposeView,
                    },
                    labels: outlets,
                    plotOptions: {
                        bar: {
                            columnWidth: '10%',
                            distributed: true,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return `KWD ${val}`;
                                }
                            }
                        }
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: outlets,
                        labels: {
                            style: {
                                fontSize: '12px'
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return `KWD ${val}`;
                            }
                        }
                    }
                },
            });
        }
        console.log(res);
    };

    const getPenaltyByType = async () => {
        const res = await DashboardService.penaltyByType({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            const series = [];
            const types = [];
            for (let i = 0; i < res.length; i++) {
                series.push(res[i].totalPenalty);
                types.push(res[i].type.title);
            }
            setPenaltyByTypeList(res);
            setPenaltyByType({
                series: penaltyByTypeView === 'pie' || penaltyByTypeView === 'donut' ? series : [{
                    data: series,
                }],
                options: {
                    chart: {
                        height: 350,
                        type: penaltyByTypeView,
                    },
                    labels: types,
                    plotOptions: {
                        bar: {
                            columnWidth: '10%',
                            distributed: true,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return `KWD ${val}`;
                                }
                            }
                        }
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: types,
                        labels: {
                            style: {
                                fontSize: '12px'
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return `KWD ${val}`;
                            }
                        }
                    }
                },
            });
        }
        console.log(res);
    };

    const getPenaltyByChain = async () => {
        const res = await DashboardService.penaltyByChain({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            const series = [];
            const items = [];
            for (let i = 0; i < res.length; i++) {
                series.push(res[i].totalPenalty);
                items.push(res[i].chain.address);
            }
            setPenaltyByChainList(res);
            setPenaltyByChain({
                series: penaltyByChainView === 'pie' || penaltyByChainView === 'donut' ? series : [{
                    data: series,
                }],
                options: {
                    chart: {
                        height: 350,
                        type: penaltyByChainView,
                    },
                    labels: items,
                    plotOptions: {
                        bar: {
                            columnWidth: '10%',
                            distributed: true,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return `KWD ${val}`;
                                }
                            }
                        }
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: items,
                        labels: {
                            style: {
                                fontSize: '12px'
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return `KWD ${val}`;
                            }
                        }
                    }
                },
            });
        }
        console.log(res);
    };

    const getPenaltyByDriver = async () => {
        const res = await DashboardService.penaltyByDriver({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            const series = [];
            const items = [];
            for (let i = 0; i < res.length; i++) {
                series.push(res[i].totalPenalty);
                items.push(res[i].driver.name);
            }
            setPenaltyByDriverList(res);
            setPenaltyByDriver({
                series: penaltyByDriverView === 'pie' || penaltyByDriverView === 'donut' ? series : [{
                    data: series,
                }],
                options: {
                    chart: {
                        height: 350,
                        type: penaltyByDriverView,
                    },
                    labels: items,
                    plotOptions: {
                        bar: {
                            columnWidth: '10%',
                            distributed: true,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return `KWD ${val}`;
                                }
                            }
                        }
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: items,
                        labels: {
                            style: {
                                fontSize: '12px'
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return `KWD ${val}`;
                            }
                        }
                    }
                },
            });
        }
        console.log(res);
    };

    const getPenaltyByVehicle = async () => {
        const res = await DashboardService.penaltyByVehicle({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            const series = [];
            const items = [];
            for (let i = 0; i < res.length; i++) {
                series.push(res[i].totalPenalty);
                items.push(res[i].vehicle.number);
            }
            setPenaltyByVehicleList(res);
            setPenaltyByVehicle({
                series: penaltyByVehicleView === 'pie' || penaltyByVehicleView === 'donut' ? series : [{
                    data: series,
                }],
                options: {
                    chart: {
                        height: 350,
                        type: penaltyByVehicleView,
                    },
                    labels: items,
                    plotOptions: {
                        bar: {
                            columnWidth: '10%',
                            distributed: true,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return `KWD ${val}`;
                                }
                            }
                        }
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: items,
                        labels: {
                            style: {
                                fontSize: '12px'
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return `KWD ${val}`;
                            }
                        }
                    }
                },
            });
        }
        console.log(res);
    };


    const getTopExpensiveVehicle = async () => {
        const res = await DashboardService.topExpensiveVehicle({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            setTopExpensiveVehicle(res);
        }
        console.log(res);
    };

    const getTopExpensiveDrivers = async () => {
        const res = await DashboardService.topExpensiveDrivers({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            setTopExpensiveDriver(res);
        }
        console.log(res);
    };

    const getVehicleExpenseByCategories = async () => {
        const res = await DashboardService.getTotalExpenseVehicleByCategory({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
        });
        if (res) {
            setVehicleExpenseByCategories(res);
        }
        console.log(res);
    };

    const getVehicleExpenseByTypes = async () => {
        const res = await DashboardService.getTotalExpenseVehicleByType({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            setVehicleExpenseByTypes(res);
        }
        console.log(res);
    };

    const getTotalExpenseOutletByVehicle = async () => {
        const res = await DashboardService.getTotalExpenseOutletByVehicle({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            setOutletExpenseByVehicles(res);
        }
        console.log(res);
    };

    const getTotalExpenseVehicleByOutlet = async () => {
        const res = await DashboardService.getTotalExpenseVehicleByOutlet({
            ...(companies.length > 0 && {companies: companies.map((i) => i.value)}),
            ...(chains.length > 0 && {chains: chains.map((i) => i.value)}),
            ...(outlets.length > 0 && {outlets: outlets.map((i) => i.value)}),
            ...(categories.length > 0 && {categories: categories.map((i) => i.value)}),
            ...(types.length > 0 && {types: types.map((i) => i.value)}),
            ...(statuses.length > 0 && {statuses: statuses.map((i) => i.value)}),
            ...(drivers.length > 0 && {drivers: drivers.map((i) => i.value)}),
            ...(vehicles.length > 0 && {vehicles: vehicles.map((i) => i.value)}),
            ...(startDate && {start: startDate}),
            ...(endDate && {end: endDate}),
        });
        if (res) {
            setVehicleExpenseByOutlet(res);
        }
        console.log(res);
    };

    useEffect(() => {
        getExpenseByCategories();
        // Set value in localstorage for chart view
        if (vehicleExpenseByCategoryView) {
            localStorage.setItem('vehicleExpenseByCategoryView', vehicleExpenseByCategoryView);
        }
    }, [vehicleExpenseByCategoryView]);

    useEffect(() => {
        getAverageExpenseByMonth();
        // Set value in localstorage for chart view
        if (vehicleAverageExpenseByMonthView) {
            localStorage.setItem('vehicleAverageExpenseByMonthView', vehicleAverageExpenseByMonthView);
        }
    }, [vehicleAverageExpenseByMonthView]);

    useEffect(() => {
        getDailyExpense();
        // Set value in localstorage for chart view
        if (dailyExpenseView) {
            localStorage.setItem('dailyExpenseView', dailyExpenseView);
        }
    }, [dailyExpenseView]);

    useEffect(() => {
        getExpenseByCompanies();
        // Set value in localstorage for chart view
        if (vehicleExpenseByCompanyView) {
            localStorage.setItem('vehicleExpenseByCompanyView', vehicleExpenseByCompanyView);
        }
    }, [vehicleExpenseByCompanyView]);

    useEffect(() => {
        getExpenseByChains();
        // Set value in localstorage for chart view
        if (vehicleExpenseByChainView) {
            localStorage.setItem('vehicleExpenseByChainView', vehicleExpenseByChainView);
        }
    }, [vehicleExpenseByChainView]);

    useEffect(() => {
        getExpenseByOutlets();
        // Set value in localstorage for chart view
        if (vehicleExpenseByOutletView) {
            localStorage.setItem('vehicleExpenseByOutletView', vehicleExpenseByOutletView);
        }
    }, [vehicleExpenseByOutletView]);

    useEffect(() => {
        getExpenseByPurpose();
        // Set value in localstorage for chart view
        if (vehicleExpenseByPurposeView) {
            localStorage.setItem('vehicleExpenseByPurposeView', vehicleExpenseByPurposeView);
        }
    }, [vehicleExpenseByPurposeView]);

    useEffect(() => {
        getPenaltyByType();
        // Set value in localstorage for chart view
        if (penaltyByTypeView) {
            localStorage.setItem('penaltyByTypeView', penaltyByTypeView);
        }
    }, [penaltyByTypeView]);

    useEffect(() => {
        getPenaltyByChain();
        // Set value in localstorage for chart view
        if (penaltyByChainView) {
            localStorage.setItem('penaltyByChainView', penaltyByChainView);
        }
    }, [penaltyByChainView]);

    useEffect(() => {
        getPenaltyByDriver();
        // Set value in localstorage for chart view
        if (penaltyByDriverView) {
            localStorage.setItem('penaltyByDriverView', penaltyByDriverView);
        }
    }, [penaltyByDriverView]);

    useEffect(() => {
        getPenaltyByVehicle();
        // Set value in localstorage for chart view
        if (penaltyByVehicleView) {
            localStorage.setItem('penaltyByVehicleView', penaltyByVehicleView);
        }
    }, [penaltyByVehicleView]);

    const getData = async () => {
        await getExpenseByCategories();
        await getAverageExpenseByMonth();
        await getDailyExpense();
        await getExpenseByCompanies();
        await getExpenseByChains();
        await getExpenseByOutlets();
        await getTopExpensiveVehicle();
        await getTopExpensiveDrivers();
        await getVehicleExpenseByCategories();
        await getVehicleExpenseByTypes();
        await getTotalExpenseOutletByVehicle();
        await getTotalExpenseVehicleByOutlet();
        await getExpenseByPurpose();
    }

    // Load Chart View from localstorage
    useEffect(() => {
        const vehicleExpenseByCategoryView = localStorage.getItem('vehicleExpenseByCategoryView') ?? 'bar';
        setVehicleExpenseByCategoryView(vehicleExpenseByCategoryView);

        const vehicleAverageExpenseByMonthView = localStorage.getItem('vehicleAverageExpenseByMonthView') ?? 'bar';
        setVehicleAverageExpenseByMonthView(vehicleAverageExpenseByMonthView);

        const dailyExpenseView = localStorage.getItem('dailyExpenseView') ?? 'bar';
        setDailyExpenseView(dailyExpenseView);

        const vehicleExpenseByCompanyView = localStorage.getItem('vehicleExpenseByCompanyView') ?? 'bar';
        setVehicleExpenseByCompanyView(vehicleExpenseByCompanyView);

        const vehicleExpenseByChainView = localStorage.getItem('vehicleExpenseByChainView') ?? 'bar';
        setVehicleExpenseByChainView(vehicleExpenseByChainView);

        const vehicleExpenseByOutletView = localStorage.getItem('vehicleExpenseByOutletView') ?? 'bar';
        setVehicleExpenseByOutletView(vehicleExpenseByOutletView);

        const vehicleExpenseByPurposeView = localStorage.getItem('vehicleExpenseByPurposeView') ?? 'bar';
        setVehicleExpenseByPurposeView(vehicleExpenseByPurposeView);

        const penaltyByTypeView = localStorage.getItem('penaltyByTypeView') ?? 'bar';
        setPenaltyByTypeView(penaltyByTypeView);

        const penaltyByChainView = localStorage.getItem('penaltyByChainView') ?? 'bar';
        setPenaltyByChainView(penaltyByChainView);

        const penaltyByDriverView = localStorage.getItem('penaltyByDriverView') ?? 'bar';
        setPenaltyByDriverView(penaltyByDriverView);

        const penaltyByVehicleView = localStorage.getItem('penaltyByVehicleView') ?? 'bar';
        setPenaltyByVehicleView(penaltyByVehicleView);
    }, []);

    // Set selection range and start date and end date to current month
    useEffect(() => {
        setSelectionRange({
            startDate: new Date(moment().startOf('month').toISOString()),
            endDate: new Date(moment().endOf('month').toISOString()),
            key: 'selection',
        });
        setStartDate(moment().startOf('month').toISOString());
        setEndDate(moment().endOf('month').toISOString());
    }, []);

    useEffect(() => {
    //     Set initial data
        setCompanies(admin.companies.length > 0 ? admin.companies.map((company) => {
            return { label: company.name, value: company._id };
        }) : []);
        setChains(admin.chains.length > 0 ? admin.chains.map((chain) => {
            return { label: chain.address, value: chain._id };
        }) : []);
        setOutlets(admin.outlets.length > 0 ? admin.outlets.map((outlet) => {
            return { label: outlet.location, value: outlet._id };
        }) : []);
    }, []);

    return (
        <>
            {
                !permissions.view &&
                <p className='text-center'>You do not have permission to view this section</p>
            }
            {
                permissions.view && <>
                    {/* Value Filters */}
                    <div className="card mt-3">
                        <div className="card-header p-3 d-flex justify-content-between">
                            <h5 className="card-title">Filters</h5>

                            <div className={'d-flex'}>
                                <button type="button" className="btn btn-secondary me-4" onClick={() => {
                                    setCompanies([]);
                                    setChains([]);
                                    setOutlets([]);
                                    setVehicles([]);
                                    setDrivers([]);
                                    setCategories([]);
                                    setTypes([]);
                                    setStatuses([
                                        {label: 'Pending', value: 'Pending'},
                                        {label: 'Manager Approved', value: 'Manager Approved'},
                                        {label: 'Operational Manager Approved', value: 'Operational Manager Approved'},
                                        {label: 'Completed', value: 'Completed'},
                                    ]);
                                    setStartDate(moment().startOf('month').toISOString());
                                    setEndDate(moment().endOf('month').toISOString());
                                }}>Reset
                                </button>
                                <button className={'btn btn-primary bg-primary-light'} onClick={() => {
                                    setShowFilters(!showFilters);
                                }}><i className={!showFilters ? 'ri-eye-line' : 'ri-eye-off-line'}></i></button>
                            </div>
                        </div>
                        {showFilters && <div className="card-body">
                            <div className={"row mb-5"}>
                                <div className="mb-3 col-sm-12 col-md-4">
                                    <label htmlFor="type" className="form-label">Companies</label>
                                    <Select
                                        name="chain"
                                        options={companyOptions}
                                        value={
                                            // Check if admin has some chains assigned to it
                                            admin.companies.length > 0 ? admin.companies.map((company) => {
                                                return {label: company.name, value: company._id};
                                            }) : chains
                                        }
                                        isDisabled={admin.companies.length > 0}
                                        onChange={setCompanies}
                                        isMulti={true}/>
                                </div>

                                <div className="mb-3 col-sm-12 col-md-4">
                                    <label htmlFor="type" className="form-label">Chains</label>
                                    <Select
                                        name="chain"
                                        options={chainOptions}
                                        value={
                                        // Check if admin has some chains assigned to it
                                          admin.chains.length > 0 ? admin.chains.map((chain) => {
                                              return {label: chain.address, value: chain._id};
                                          }) : chains
                                        }
                                        isDisabled={admin.chains.length > 0}
                                        onChange={setChains}
                                        isMulti={true}/>
                                </div>

                                <div className="mb-3 col-sm-12 col-md-4">
                                    <label htmlFor="type" className="form-label">Outlets</label>
                                    <Select
                                        name="chain"
                                        options={outletOptions}
                                        value={
                                        // Check if admin has some chains assigned to it
                                          admin.outlets.length > 0 ? admin.outlets.map((outlet) => {
                                              return {label: outlet.location, value: outlet._id};
                                          }) : chains
                                        }
                                        isDisabled={admin.outlets.length > 0}
                                        onChange={setOutlets}
                                        isMulti={true}/>
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6">
                                    <label htmlFor="type" className="form-label">Vehicle</label>
                                    <Select
                                        name="chain"
                                        options={vehicleOptions}
                                        value={vehicles}
                                        onChange={setVehicles}
                                        isMulti={true}/>
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6">
                                    <label htmlFor="type" className="form-label">Driver</label>
                                    <Select
                                        name="chain"
                                        options={driverOptions}
                                        value={drivers}
                                        onChange={setDrivers}
                                        isMulti={true}/>
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6">
                                    <label htmlFor="type" className="form-label">Categories</label>
                                    <Select
                                        name="chain"
                                        options={categoryOptions}
                                        value={categories}
                                        onChange={setCategories}
                                        isMulti={true}/>
                                </div>
                                <div className="mb-3 col-sm-12 col-md-6">
                                    <label htmlFor="type" className="form-label">Types</label>
                                    <Select
                                        name="chain"
                                        options={typeOptions}
                                        value={types}
                                        onChange={setTypes}
                                        isMulti={true}
                                    />
                                </div>
                                <div className="mb-3 col-sm-12 col-md-4">
                                    <label htmlFor="type" className="form-label">Status</label>
                                    <Select
                                        name="chain"
                                        options={statusOptions}
                                        value={statuses}
                                        onChange={setStatuses} isMulti={true}/>
                                </div>
                            </div>

                        </div>}
                    </div>

                    {/* Date Filters */}
                    <div className="card mt-3">
                        <div className="card-header p-3 d-flex justify-content-between">
                            <h5 className="card-title">Date Filter</h5>
                            <button className={'btn btn-primary bg-primary-light'} onClick={() => {
                                setShowDateFilter(!showDateFilter);
                            }}><i className={!showDateFilter ? 'ri-eye-line' : 'ri-eye-off-line'}></i></button>
                        </div>
                        {showDateFilter && <div className="card-body">
                            <div className={"row mb-5"}>
                                <div className={"col-12 mt-5 d-flex justify-content-center w-100"}>
                                    <DateRangePicker
                                        months={2}
                                        ranges={[selectionRange]}
                                        direction="horizontal"
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        onChange={(ranges) => {
                                            console.log('ranges', ranges);
                                            setSelectionRange(ranges.selection);
                                            setStartDate(moment(ranges.selection.startDate).toISOString());
                                            setEndDate(moment(ranges.selection.endDate).toISOString());
                                        }}
                                    />
                                </div>
                            </div>


                        </div>}
                    </div>

                    {
                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c1')).length > 0 &&
                        <div className="card mt-3">
                            <div className="card-header p-3 d-flex justify-content-between">
                                <h5 className="card-title">Total Vehicle Expenses By Category</h5>
                                <div className="">
                                    <Select
                                        name="view"
                                        options={[
                                            {label: 'Bar', value: 'bar'},
                                            {label: 'Line', value: 'line'},
                                            {label: 'Area', value: 'area'},
                                            {label: 'Scatter', value: 'scatter'},
                                            {label: 'Pie', value: 'pie'},
                                            {label: 'Donut', value: 'donut'},
                                            {label: 'Table', value: 'table'},
                                        ]}
                                        placeholder={'View'}
                                        value={vehicleExpenseByCategoryView}
                                        onChange={(e) => setVehicleExpenseByCategoryView(e.value)}/>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    vehicleExpenseByCategoryView !== 'table' && <>
                                        {
                                            vehicleExpenseByCategoryView === 'bar' &&
                                            <ReactApexChart options={vehicleExpenseByCategory.options}
                                                            series={vehicleExpenseByCategory.series} type="bar" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByCategoryView === 'line' &&
                                            <ReactApexChart options={vehicleExpenseByCategory.options}
                                                            series={vehicleExpenseByCategory.series} type="line" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByCategoryView === 'area' &&
                                            <ReactApexChart options={vehicleExpenseByCategory.options}
                                                            series={vehicleExpenseByCategory.series} type="area" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByCategoryView === 'pie' &&
                                            <ReactApexChart options={vehicleExpenseByCategory.options}
                                                            series={vehicleExpenseByCategory.series} type="pie" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByCategoryView === 'donut' &&
                                            <ReactApexChart options={vehicleExpenseByCategory.options}
                                                            series={vehicleExpenseByCategory.series} type="donut" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByCategoryView === 'scatter' &&
                                            <ReactApexChart options={vehicleExpenseByCategory.options}
                                                            series={vehicleExpenseByCategory.series} type="scatter"
                                                            height={350}/>
                                        }
                                    </>
                                }
                                {
                                    vehicleExpenseByCategoryView === 'table' && <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Category</th>
                                                <th scope="col">Total Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                vehicleExpenseByCategoryList.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.category}</td>
                                                        <td>KWD {item.totalAmount}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        </div>
                    }

                    {
                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c2')).length > 0 &&
                        <div className="card mt-3">
                            <div className="card-header p-3 d-flex justify-content-between">
                                <h5 className="card-title">Average Vehicle Expenses By Month</h5>
                                <div className="">
                                    <Select
                                        name="view"
                                        options={[
                                            {label: 'Bar', value: 'bar'},
                                            {label: 'Line', value: 'line'},
                                            {label: 'Area', value: 'area'},
                                            {label: 'Scatter', value: 'scatter'},
                                            {label: 'Pie', value: 'pie'},
                                            {label: 'Donut', value: 'donut'},
                                            {label: 'Table', value: 'table'},
                                        ]}
                                        placeholder={'View'}
                                        value={vehicleAverageExpenseByMonthView}
                                        onChange={(e) => setVehicleAverageExpenseByMonthView(e.value)}/>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    vehicleAverageExpenseByMonthView !== 'table' && <>
                                        {
                                            vehicleAverageExpenseByMonthView === 'bar' &&
                                            <ReactApexChart options={vehicleAverageExpenseByMonth.options}
                                                            series={vehicleAverageExpenseByMonth.series} type="bar"
                                                            height={350}/>
                                        }
                                        {
                                            vehicleAverageExpenseByMonthView === 'line' &&
                                            <ReactApexChart options={vehicleAverageExpenseByMonth.options}
                                                            series={vehicleAverageExpenseByMonth.series} type="line"
                                                            height={350}/>
                                        }
                                        {
                                            vehicleAverageExpenseByMonthView === 'area' &&
                                            <ReactApexChart options={vehicleAverageExpenseByMonth.options}
                                                            series={vehicleAverageExpenseByMonth.series} type="area"
                                                            height={350}/>
                                        }
                                        {
                                            vehicleAverageExpenseByMonthView === 'scatter' &&
                                            <ReactApexChart options={vehicleAverageExpenseByMonth.options}
                                                            series={vehicleAverageExpenseByMonth.series} type="scatter"
                                                            height={350}/>
                                        }
                                        {
                                            vehicleAverageExpenseByMonthView === 'pie' &&
                                            <ReactApexChart options={vehicleAverageExpenseByMonth.options}
                                                            series={vehicleAverageExpenseByMonth.series} type="pie"
                                                            height={350}/>
                                        }
                                        {
                                            vehicleAverageExpenseByMonthView === 'donut' &&
                                            <ReactApexChart options={vehicleAverageExpenseByMonth.options}
                                                            series={vehicleAverageExpenseByMonth.series} type="donut"
                                                            height={350}/>
                                        }
                                    </>
                                }
                                {
                                    vehicleAverageExpenseByMonthView === 'table' && <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Vehicle</th>
                                                <th scope="col">Total Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                vehicleAverageExpenseByMonthList.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.vehicleDetails.number}</td>
                                                        <td>KWD {item.averageExpense}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        </div>
                    }

                    {
                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c3')).length > 0 &&
                        <div className="card mt-3">
                            <div className="card-header p-3 d-flex justify-content-between">
                                <h5 className="card-title">Daily Vehicle Expenses</h5>
                                <div className="">
                                    <Select
                                        name="view"
                                        options={[
                                            {label: 'Bar', value: 'bar'},
                                            {label: 'Line', value: 'line'},
                                            {label: 'Area', value: 'area'},
                                            {label: 'Scatter', value: 'scatter'},
                                            {label: 'Pie', value: 'pie'},
                                            {label: 'Donut', value: 'donut'},
                                            {label: 'Table', value: 'table'},
                                        ]}
                                        placeholder={'View'}
                                        value={dailyExpenseView}
                                        onChange={(e) => setDailyExpenseView(e.value)}/>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    dailyExpenseView !== 'table' && <>
                                        {
                                            dailyExpenseView === 'bar' &&
                                            <ReactApexChart options={dailyExpense.options} series={dailyExpense.series}
                                                            type="bar"
                                                            height={350}/>
                                        }
                                        {
                                            dailyExpenseView === 'line' &&
                                            <ReactApexChart options={dailyExpense.options} series={dailyExpense.series}
                                                            type="line"
                                                            height={350}/>
                                        }
                                        {
                                            dailyExpenseView === 'area' &&
                                            <ReactApexChart options={dailyExpense.options} series={dailyExpense.series}
                                                            type="area"
                                                            height={350}/>
                                        }
                                        {
                                            dailyExpenseView === 'scatter' &&
                                            <ReactApexChart options={dailyExpense.options} series={dailyExpense.series}
                                                            type="scatter"
                                                            height={350}/>
                                        }
                                        {
                                            dailyExpenseView === 'pie' &&
                                            <ReactApexChart options={dailyExpense.options} series={dailyExpense.series}
                                                            type="pie"
                                                            height={350}/>
                                        }
                                        {
                                            dailyExpenseView === 'donut' &&
                                            <ReactApexChart options={dailyExpense.options} series={dailyExpense.series}
                                                            type="donut"
                                                            height={350}/>
                                        }
                                    </>
                                }
                                {
                                    dailyExpenseView === 'table' && <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Month</th>
                                                <th scope="col">Total Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                dailyExpenseList.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.date}</td>
                                                        <td>KWD {item.totalExpense}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        </div>
                    }

                    {
                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c4')).length > 0 &&
                        <div className="card mt-3">
                            <div className="card-header p-3 d-flex justify-content-between">
                                <h5 className="card-title">Total Expenses By Company</h5>
                                <div className="">
                                    <Select
                                        name="view"
                                        options={[
                                            {label: 'Bar', value: 'bar'},
                                            {label: 'Line', value: 'line'},
                                            {label: 'Area', value: 'area'},
                                            {label: 'Scatter', value: 'scatter'},
                                            {label: 'Pie', value: 'pie'},
                                            {label: 'Donut', value: 'donut'},
                                            {label: 'Table', value: 'table'},
                                        ]}
                                        placeholder={'View'}
                                        value={vehicleExpenseByCompanyView}
                                        onChange={(e) => setVehicleExpenseByCompanyView(e.value)}/>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    vehicleExpenseByCompanyView !== 'table' && <>
                                        {
                                            vehicleExpenseByCompanyView === 'bar' &&
                                            <ReactApexChart options={vehicleExpenseByCompany.options}
                                                            series={vehicleExpenseByCompany.series} type="bar" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByCompanyView === 'line' &&
                                            <ReactApexChart options={vehicleExpenseByCompany.options}
                                                            series={vehicleExpenseByCompany.series} type="line" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByCompanyView === 'area' &&
                                            <ReactApexChart options={vehicleExpenseByCompany.options}
                                                            series={vehicleExpenseByCompany.series} type="area" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByCompanyView === 'pie' &&
                                            <ReactApexChart options={vehicleExpenseByCompany.options}
                                                            series={vehicleExpenseByCompany.series} type="pie" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByCompanyView === 'donut' &&
                                            <ReactApexChart options={vehicleExpenseByCompany.options}
                                                            series={vehicleExpenseByCompany.series} type="donut" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByCompanyView === 'scatter' &&
                                            <ReactApexChart options={vehicleExpenseByCompany.options}
                                                            series={vehicleExpenseByCompany.series} type="scatter"
                                                            height={350}/>
                                        }
                                    </>
                                }
                                {
                                    vehicleExpenseByCompanyView === 'table' && <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Company</th>
                                                <th scope="col">Total Expense</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                vehicleExpenseByCompanyList.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.companyDetails.name}</td>
                                                        <td>KWD {item.totalExpense}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        </div>
                    }

                    {
                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c5')).length > 0 &&
                        <div className="card mt-3">
                            <div className="card-header p-3 d-flex justify-content-between">
                                <h5 className="card-title">Total Expenses By Chain</h5>
                                <div className="">
                                    <Select
                                        name="view"
                                        options={[
                                            {label: 'Bar', value: 'bar'},
                                            {label: 'Line', value: 'line'},
                                            {label: 'Area', value: 'area'},
                                            {label: 'Scatter', value: 'scatter'},
                                            {label: 'Pie', value: 'pie'},
                                            {label: 'Donut', value: 'donut'},
                                            {label: 'Table', value: 'table'},
                                        ]}
                                        placeholder={'View'}
                                        value={vehicleExpenseByChainView}
                                        onChange={(e) => setVehicleExpenseByChainView(e.value)}/>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    vehicleExpenseByChainView !== 'table' && <>
                                        {
                                            vehicleExpenseByChainView === 'bar' &&
                                            <ReactApexChart options={vehicleExpenseByChain.options}
                                                            series={vehicleExpenseByChain.series} type="bar" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByChainView === 'line' &&
                                            <ReactApexChart options={vehicleExpenseByChain.options}
                                                            series={vehicleExpenseByChain.series} type="line" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByChainView === 'area' &&
                                            <ReactApexChart options={vehicleExpenseByChain.options}
                                                            series={vehicleExpenseByChain.series} type="area" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByChainView === 'pie' &&
                                            <ReactApexChart options={vehicleExpenseByChain.options}
                                                            series={vehicleExpenseByChain.series} type="pie" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByChainView === 'donut' &&
                                            <ReactApexChart options={vehicleExpenseByChain.options}
                                                            series={vehicleExpenseByChain.series} type="donut" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByChainView === 'scatter' &&
                                            <ReactApexChart options={vehicleExpenseByChain.options}
                                                            series={vehicleExpenseByChain.series} type="scatter" height={350}/>
                                        }
                                    </>
                                }
                                {
                                    vehicleExpenseByChainView === 'table' && <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Chain</th>
                                                <th scope="col">Total Expense</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                vehicleExpenseByChainList.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.chainDetails.address}</td>
                                                        <td>KWD {item.totalExpense}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        </div>
                    }

                    {
                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c6')).length > 0 &&
                        <div className="card mt-3">
                            <div className="card-header p-3 d-flex justify-content-between">
                                <h5 className="card-title">Total Expenses By Outlet</h5>
                                <div className="">
                                    <Select
                                        name="view"
                                        options={[
                                            {label: 'Bar', value: 'bar'},
                                            {label: 'Line', value: 'line'},
                                            {label: 'Area', value: 'area'},
                                            {label: 'Scatter', value: 'scatter'},
                                            {label: 'Pie', value: 'pie'},
                                            {label: 'Donut', value: 'donut'},
                                            {label: 'Table', value: 'table'},
                                        ]}
                                        placeholder={'View'}
                                        value={vehicleExpenseByOutletView}
                                        onChange={(e) => setVehicleExpenseByOutletView(e.value)}/>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    vehicleExpenseByOutletView !== 'table' && <>
                                        {
                                            vehicleExpenseByOutletView === 'bar' &&
                                            <ReactApexChart options={vehicleExpenseByOutlets.options}
                                                            series={vehicleExpenseByOutlets.series} type="bar" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByOutletView === 'line' &&
                                            <ReactApexChart options={vehicleExpenseByOutlets.options}
                                                            series={vehicleExpenseByOutlets.series} type="line" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByOutletView === 'area' &&
                                            <ReactApexChart options={vehicleExpenseByOutlets.options}
                                                            series={vehicleExpenseByOutlets.series} type="area" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByOutletView === 'pie' &&
                                            <ReactApexChart options={vehicleExpenseByOutlets.options}
                                                            series={vehicleExpenseByOutlets.series} type="pie" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByOutletView === 'donut' &&
                                            <ReactApexChart options={vehicleExpenseByOutlets.options}
                                                            series={vehicleExpenseByOutlets.series} type="donut" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByOutletView === 'scatter' &&
                                            <ReactApexChart options={vehicleExpenseByOutlets.options}
                                                            series={vehicleExpenseByOutlets.series} type="scatter"
                                                            height={350}/>
                                        }
                                    </>
                                }
                                {
                                    vehicleExpenseByOutletView === 'table' && <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Outlet</th>
                                                <th scope="col">Total Expense</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                vehicleExpenseByOutletList.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.outletDetails.location}</td>
                                                        <td>KWD {item.totalExpense}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        </div>
                    }

                    {
                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c7')).length > 0 &&
                        <div className="card mt-3">
                            <div className="card-header p-3 d-flex justify-content-between">
                                <h5 className="card-title">Total Expenses By Purpose</h5>
                                <div className="">
                                    <Select
                                        name="view"
                                        options={[
                                            {label: 'Bar', value: 'bar'},
                                            {label: 'Line', value: 'line'},
                                            {label: 'Area', value: 'area'},
                                            {label: 'Scatter', value: 'scatter'},
                                            {label: 'Pie', value: 'pie'},
                                            {label: 'Donut', value: 'donut'},
                                            {label: 'Table', value: 'table'},
                                        ]}
                                        placeholder={'View'}
                                        value={vehicleExpenseByPurposeView}
                                        onChange={(e) => setVehicleExpenseByPurposeView(e.value)}/>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    vehicleExpenseByPurposeView !== 'table' && <>
                                        {
                                            vehicleExpenseByPurposeView === 'bar' &&
                                            <ReactApexChart options={vehicleExpenseByPurpose.options}
                                                            series={vehicleExpenseByPurpose.series} type="bar" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByPurposeView === 'line' &&
                                            <ReactApexChart options={vehicleExpenseByPurpose.options}
                                                            series={vehicleExpenseByPurpose.series} type="line" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByPurposeView === 'area' &&
                                            <ReactApexChart options={vehicleExpenseByPurpose.options}
                                                            series={vehicleExpenseByPurpose.series} type="area" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByPurposeView === 'pie' &&
                                            <ReactApexChart options={vehicleExpenseByPurpose.options}
                                                            series={vehicleExpenseByPurpose.series} type="pie" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByPurposeView === 'donut' &&
                                            <ReactApexChart options={vehicleExpenseByPurpose.options}
                                                            series={vehicleExpenseByPurpose.series} type="donut" height={350}/>
                                        }
                                        {
                                            vehicleExpenseByPurposeView === 'scatter' &&
                                            <ReactApexChart options={vehicleExpenseByPurpose.options}
                                                            series={vehicleExpenseByPurpose.series} type="scatter"
                                                            height={350}/>
                                        }
                                    </>
                                }
                                {
                                    vehicleExpenseByPurposeView === 'table' && <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Purpose</th>
                                                <th scope="col">Total Expense</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                vehicleExpenseByPurposeList.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.purpose ?? 'Default'}</td>
                                                        <td>KWD {item.totalExpense}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        </div>
                    }

                    {
                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c8')).length > 0 &&
                        <div className="card mt-3">
                            <div className="card-header p-3 d-flex justify-content-between">
                                <h5 className="card-title">Penalties By Type</h5>
                                <div className="">
                                    <Select
                                        name="view"
                                        options={[
                                            {label: 'Bar', value: 'bar'},
                                            {label: 'Line', value: 'line'},
                                            {label: 'Area', value: 'area'},
                                            {label: 'Scatter', value: 'scatter'},
                                            {label: 'Pie', value: 'pie'},
                                            {label: 'Donut', value: 'donut'},
                                            {label: 'Table', value: 'table'},
                                        ]}
                                        placeholder={'View'}
                                        value={penaltyByTypeView}
                                        onChange={(e) => setPenaltyByTypeView(e.value)}/>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    penaltyByTypeView !== 'table' && <>
                                        {
                                            penaltyByTypeView === 'bar' &&
                                            <ReactApexChart options={penaltyByType.options}
                                                            series={penaltyByType.series} type="bar" height={350}/>
                                        }
                                        {
                                            penaltyByTypeView === 'line' &&
                                            <ReactApexChart options={penaltyByType.options}
                                                            series={penaltyByType.series} type="line" height={350}/>
                                        }
                                        {
                                            penaltyByTypeView === 'area' &&
                                            <ReactApexChart options={penaltyByType.options}
                                                            series={penaltyByType.series} type="area" height={350}/>
                                        }
                                        {
                                            penaltyByTypeView === 'pie' &&
                                            <ReactApexChart options={penaltyByType.options}
                                                            series={penaltyByType.series} type="pie" height={350}/>
                                        }
                                        {
                                            penaltyByTypeView === 'donut' &&
                                            <ReactApexChart options={penaltyByType.options}
                                                            series={penaltyByType.series} type="donut" height={350}/>
                                        }
                                        {
                                            penaltyByTypeView === 'scatter' &&
                                            <ReactApexChart options={penaltyByType.options}
                                                            series={penaltyByType.series} type="scatter" height={350}/>
                                        }
                                    </>
                                }
                                {
                                    penaltyByTypeView === 'table' && <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Type</th>
                                                <th scope="col">Total Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                penaltyByTypeList.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.type.title ?? 'Default'}</td>
                                                        <td>KWD {item.totalPenalty}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        </div>
                    }

                    {
                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c9')).length > 0 &&
                        <div className="card mt-3">
                            <div className="card-header p-3 d-flex justify-content-between">
                                <h5 className="card-title">Penalties By Chain</h5>
                                <div className="">
                                    <Select
                                        name="view"
                                        options={[
                                            {label: 'Bar', value: 'bar'},
                                            {label: 'Line', value: 'line'},
                                            {label: 'Area', value: 'area'},
                                            {label: 'Scatter', value: 'scatter'},
                                            {label: 'Pie', value: 'pie'},
                                            {label: 'Donut', value: 'donut'},
                                            {label: 'Table', value: 'table'},
                                        ]}
                                        placeholder={'View'}
                                        value={penaltyByChainView}
                                        onChange={(e) => setPenaltyByChainView(e.value)}/>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    penaltyByChainView !== 'table' && <>
                                        {
                                            penaltyByChainView === 'bar' &&
                                            <ReactApexChart options={penaltyByChain.options}
                                                            series={penaltyByChain.series} type="bar" height={350}/>
                                        }
                                        {
                                            penaltyByChainView === 'line' &&
                                            <ReactApexChart options={penaltyByChain.options}
                                                            series={penaltyByChain.series} type="line" height={350}/>
                                        }
                                        {
                                            penaltyByChainView === 'area' &&
                                            <ReactApexChart options={penaltyByChain.options}
                                                            series={penaltyByChain.series} type="area" height={350}/>
                                        }
                                        {
                                            penaltyByChainView === 'pie' &&
                                            <ReactApexChart options={penaltyByChain.options}
                                                            series={penaltyByChain.series} type="pie" height={350}/>
                                        }
                                        {
                                            penaltyByChainView === 'donut' &&
                                            <ReactApexChart options={penaltyByChain.options}
                                                            series={penaltyByChain.series} type="donut" height={350}/>
                                        }
                                        {
                                            penaltyByChainView === 'scatter' &&
                                            <ReactApexChart options={penaltyByChain.options}
                                                            series={penaltyByChain.series} type="scatter" height={350}/>
                                        }
                                    </>
                                }
                                {
                                    penaltyByChainView === 'table' && <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Chain</th>
                                                <th scope="col">Total Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                penaltyByChainList.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.chain.address ?? 'Default'}</td>
                                                        <td>KWD {item.totalPenalty}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        </div>
                    }

                    {
                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c10')).length > 0 &&
                        <div className="card mt-3">
                            <div className="card-header p-3 d-flex justify-content-between">
                                <h5 className="card-title">Penalties By Driver</h5>
                                <div className="">
                                    <Select
                                        name="view"
                                        options={[
                                            {label: 'Bar', value: 'bar'},
                                            {label: 'Line', value: 'line'},
                                            {label: 'Area', value: 'area'},
                                            {label: 'Scatter', value: 'scatter'},
                                            {label: 'Pie', value: 'pie'},
                                            {label: 'Donut', value: 'donut'},
                                            {label: 'Table', value: 'table'},
                                        ]}
                                        placeholder={'View'}
                                        value={penaltyByDriverView}
                                        onChange={(e) => setPenaltyByDriverView(e.value)}/>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    penaltyByDriverView !== 'table' && <>
                                        {
                                            penaltyByDriverView === 'bar' &&
                                            <ReactApexChart options={penaltyByDriver.options}
                                                            series={penaltyByDriver.series} type="bar" height={350}/>
                                        }
                                        {
                                            penaltyByDriverView === 'line' &&
                                            <ReactApexChart options={penaltyByDriver.options}
                                                            series={penaltyByDriver.series} type="line" height={350}/>
                                        }
                                        {
                                            penaltyByDriverView === 'area' &&
                                            <ReactApexChart options={penaltyByDriver.options}
                                                            series={penaltyByDriver.series} type="area" height={350}/>
                                        }
                                        {
                                            penaltyByDriverView === 'pie' &&
                                            <ReactApexChart options={penaltyByDriver.options}
                                                            series={penaltyByDriver.series} type="pie" height={350}/>
                                        }
                                        {
                                            penaltyByDriverView === 'donut' &&
                                            <ReactApexChart options={penaltyByDriver.options}
                                                            series={penaltyByDriver.series} type="donut" height={350}/>
                                        }
                                        {
                                            penaltyByDriverView === 'scatter' &&
                                            <ReactApexChart options={penaltyByDriver.options}
                                                            series={penaltyByDriver.series} type="scatter" height={350}/>
                                        }
                                    </>
                                }
                                {
                                    penaltyByDriverView === 'table' && <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Driver</th>
                                                <th scope="col">Total Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                penaltyByDriverList.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.driver.name ?? 'Default'}</td>
                                                        <td>KWD {item.totalPenalty}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        </div>
                    }

                    {
                        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c11')).length > 0 &&
                        <div className="card mt-3">
                            <div className="card-header p-3 d-flex justify-content-between">
                                <h5 className="card-title">Penalties By Vehicle</h5>
                                <div className="">
                                    <Select
                                        name="view"
                                        options={[
                                            {label: 'Bar', value: 'bar'},
                                            {label: 'Line', value: 'line'},
                                            {label: 'Area', value: 'area'},
                                            {label: 'Scatter', value: 'scatter'},
                                            {label: 'Pie', value: 'pie'},
                                            {label: 'Donut', value: 'donut'},
                                            {label: 'Table', value: 'table'},
                                        ]}
                                        placeholder={'View'}
                                        value={penaltyByVehicleView}
                                        onChange={(e) => setPenaltyByVehicleView(e.value)}/>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    penaltyByVehicleView !== 'table' && <>
                                        {
                                            penaltyByVehicleView === 'bar' &&
                                            <ReactApexChart options={penaltyByVehicle.options}
                                                            series={penaltyByVehicle.series} type="bar" height={350}/>
                                        }
                                        {
                                            penaltyByVehicleView === 'line' &&
                                            <ReactApexChart options={penaltyByVehicle.options}
                                                            series={penaltyByVehicle.series} type="line" height={350}/>
                                        }
                                        {
                                            penaltyByVehicleView === 'area' &&
                                            <ReactApexChart options={penaltyByVehicle.options}
                                                            series={penaltyByVehicle.series} type="area" height={350}/>
                                        }
                                        {
                                            penaltyByVehicleView === 'pie' &&
                                            <ReactApexChart options={penaltyByVehicle.options}
                                                            series={penaltyByVehicle.series} type="pie" height={350}/>
                                        }
                                        {
                                            penaltyByVehicleView === 'donut' &&
                                            <ReactApexChart options={penaltyByVehicle.options}
                                                            series={penaltyByVehicle.series} type="donut" height={350}/>
                                        }
                                        {
                                            penaltyByVehicleView === 'scatter' &&
                                            <ReactApexChart options={penaltyByVehicle.options}
                                                            series={penaltyByVehicle.series} type="scatter" height={350}/>
                                        }
                                    </>
                                }
                                {
                                    penaltyByVehicleView === 'table' && <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Vehicle</th>
                                                <th scope="col">Total Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                penaltyByVehicleList.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.vehicle.number ?? 'Default'}</td>
                                                        <td>KWD {item.totalPenalty}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        </div>
                    }


                    <div className="d-flex flex-wrap justify-content-between">
                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c12')).length > 0 &&
                            <div className="card mt-3 p-0 me-2 flex-grow-1">
                                <div className="card-header p-3 d-flex justify-content-between">
                                    <h5 className="card-title">Top Expensive Vehicles</h5>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Vehicle</th>
                                                <th scope="col">Total Expense</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                topExpensiveVehicle.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.vehicleDetails.number}</td>
                                                        <td>KWD {item.totalExpenses}</td>
                                                    </tr>
                                                })
                                            }
                                            {/* Total Row */}
                                            <tr>
                                                <td><b>Total</b></td>
                                                <td>
                                                    <b>KWD {topExpensiveVehicle.reduce((a, b) => a + b.totalExpenses, 0)}</b>
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        }

                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c13')).length > 0 &&
                            <div className="card mt-3 p-0 mw-2 flex-grow-1">
                                <div className="card-header p-3 d-flex justify-content-between">
                                    <h5 className="card-title">Top Expensive Drivers</h5>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Driver</th>
                                                <th scope="col">Total Expense</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                topExpensiveDriver.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.driverDetails.employeeCode}</td>
                                                        <td>KWD {item.totalExpenses}</td>
                                                    </tr>
                                                })
                                            }
                                            <tr>
                                                <td><b>Total</b></td>
                                                <td>
                                                    <b>KWD {topExpensiveDriver.reduce((a, b) => a + b.totalExpenses, 0)}</b>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        }

                    </div>
                    <div className="d-flex flex-wrap justify-content-between">
                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c14')).length > 0 &&
                            <div className="card mt-3 p-0 me-2 flex-grow-1">
                                <div className="card-header p-3 d-flex justify-content-between">
                                    <h5 className="card-title">Total Expense Vehicle By Category</h5>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Vehicles</th>
                                                {/* Categories */}
                                                {
                                                    categoryOptions.map((item, index) => {
                                                        return <th scope="col" key={index}>{item.label}</th>
                                                    })
                                                }
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                vehicleExpenseByCategories.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.vehicleDetails.number}</td>
                                                        {
                                                            categoryOptions.map((category, index) => {
                                                                return <td
                                                                    key={index}>{category.value === item.categoryDetails._id ? `KWD ${item.totalExpense}` : 0}</td>
                                                            })
                                                        }
                                                    </tr>
                                                })
                                            }
                                            <tr>
                                                <td><b>Total</b></td>
                                                {
                                                    categoryOptions.map((category, index) => {
                                                        return <td
                                                            key={index}>
                                                            <b>KWD {vehicleExpenseByCategories.filter((item) => item.categoryDetails._id === category.value).reduce((a, b) => a + b.totalExpense, 0)}</b>
                                                        </td>
                                                    })
                                                }

                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        }

                    </div>
                    <div className="d-flex flex-wrap justify-content-between">
                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c15')).length > 0 &&
                            <div className="card mt-3 p-0 me-2 flex-grow-1">
                                <div className="card-header p-3 d-flex justify-content-between">
                                    <h5 className="card-title">Total Expense Vehicle By Type</h5>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Vehicles</th>
                                                {/* Categories */}
                                                {
                                                    typeOptions.map((item, index) => {
                                                        return <th scope="col" key={index}>{item.label}</th>
                                                    })
                                                }
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                vehicleExpenseByTypes.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.vehicleDetails.number}</td>
                                                        {
                                                            typeOptions.map((category, index) => {
                                                                return <td
                                                                    key={index}>{category.value === item.typeDetails._id ? `KWD ${item.totalExpense}` : 0}</td>
                                                            })
                                                        }
                                                    </tr>
                                                })
                                            }
                                            <tr>
                                                <td><b>Total</b></td>
                                                {
                                                    typeOptions.map((category, index) => {
                                                        return <td
                                                            key={index}>
                                                            <b>KWD {vehicleExpenseByTypes.filter((item) => item.typeDetails._id === category.value).reduce((a, b) => a + b.totalExpense, 0)}</b>
                                                        </td>
                                                    })
                                                }

                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        }

                    </div>
                    <div className="d-flex flex-wrap justify-content-between">
                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c16')).length > 0 &&
                            <div className="card mt-3 p-0 me-2 flex-grow-1">
                                <div className="card-header p-3 d-flex justify-content-between">
                                    <h5 className="card-title">Total Outlet Expense By Vehicle</h5>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Outlets</th>
                                                {
                                                    vehicleOptions.map((item, index) => {
                                                        return <th scope="col" key={index}>{item.label}</th>
                                                    })
                                                }
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                outletExpenseByVehicles.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.outletDetails.location}</td>
                                                        {
                                                            vehicleOptions.map((category, index) => {
                                                                return <td
                                                                    key={index}>{category.value === item.vehicleDetails._id ? `KWD ${item.totalExpense}` : 0}</td>
                                                            })
                                                        }
                                                    </tr>
                                                })
                                            }
                                            <tr>
                                                <td><b>Total</b></td>
                                                {
                                                    vehicleOptions.map((category, index) => {
                                                        return <td
                                                            key={index}>
                                                            <b>KWD {outletExpenseByVehicles.filter((item) => item.vehicleDetails._id === category.value).reduce((a, b) => a + b.totalExpense, 0)}</b>
                                                        </td>
                                                    })
                                                }

                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        }

                    </div>
                    <div className="d-flex flex-wrap justify-content-between">
                        {
                            admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/dashboard') || permission.routes.includes('/dashboard/c17')).length > 0 &&
                            <div className="card mt-3 p-0 me-2 flex-grow-1">
                                <div className="card-header p-3 d-flex justify-content-between">
                                    <h5 className="card-title">Total Vehicle Expense By Outlet</h5>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Vehicles</th>
                                                {
                                                    outletOptions.map((item, index) => {
                                                        return <th scope="col" key={index}>{item.label}</th>
                                                    })
                                                }
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                vehicleExpenseByOutlet.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.vehicleDetails.number}</td>
                                                        {
                                                            outletOptions.map((category, index) => {
                                                                return <td
                                                                    key={index}>{category.value === item.outletDetails._id ? `KWD ${item.totalExpense}` : 0}</td>
                                                            })
                                                        }
                                                    </tr>
                                                })
                                            }
                                            <tr>
                                                <td><b>Total</b></td>
                                                {
                                                    outletOptions.map((category, index) => {
                                                        return <td
                                                            key={index}>
                                                            <b>KWD {vehicleExpenseByOutlet.filter((item) => item.outletDetails._id === category.value).reduce((a, b) => a + b.totalExpense, 0)}</b>
                                                        </td>
                                                    })
                                                }

                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        }

                    </div>
                </>
            }
        </>
    );
}

export default Dashboard;
