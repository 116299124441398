import {
    loginFailed,
    loginSuccess,
    logoutSuccess,
    profileUpdateFailed,
    profileUpdateSuccess,
    refreshTokenSuccess
} from "../store/auth/reducers";
import axios from "axios";
import baseConfig from "../Config";

export class AuthService {
    static login = (username, password) => async (dispatch) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": '',
            },
        };

        const body = JSON.stringify({ username, password });
        try {
            let res = await axios.post(
                `${baseConfig.apiBaseUrl}/auth/login`,
                body,
                config
            );
            dispatch({
                type: loginSuccess,
                payload: res.data,
            });
        }  catch(err) {
            if (err.response) {
                dispatch({
                    type: loginFailed,
                    payload: err.response.data,
                });
            }

        }

    }

    static refreshToken = () => async (dispatch) => {

        let token = localStorage.getItem('token');
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token,
                },
            };
            try {
                let res = await axios.get(
                    `${baseConfig.apiBaseUrl}/auth/refresh-token`,
                    config
                );
                dispatch({
                    type: refreshTokenSuccess,
                    payload: res.data,
                });
            } catch (err) {
                console.log(err);
            }

        }
    }

    static updateProfile = (data) => async (dispatch) => {

        let token = localStorage.getItem('token');
        if (token) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token,
                },
            };
            try {
                let res = await axios.patch(
                    `${baseConfig.apiBaseUrl}/auth/update-profile`,
                    data,
                    config
                );
                dispatch({
                    type: profileUpdateSuccess,
                    payload: res.data,
                });
            } catch (err) {
                if (err.response) {
                    dispatch({
                        type: profileUpdateFailed,
                        payload: err.response.data,
                    });
                }
            }

        }
    }

    static logout = () => async (dispatch) => {
        dispatch({
            type: logoutSuccess,
        });
    }

    requestAccountDeletion = async (email) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": '',
            },
        };

        const body = JSON.stringify({ email });
        try {
            let res = await axios.post(
                `${baseConfig.apiBaseUrl}/auth/request-account-deletion`,
                body,
                config
            );
            return res.data;
        }  catch(err) {
            if (err.response) {
                return null;
            }

        }

    }
}
