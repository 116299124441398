import {useEffect, useState} from "react";
import Select from "react-select";
import {ChainService} from "../../services/ChainService";
import {OutletService} from "../../services/OutletService";
import {CompanyService} from "../../services/CompanyService";
import {EmployeeService} from "../../services/EmployeeService";
import {VehicleService} from "../../services/VehicleService";
import {VehicleAssignmentService} from "../../services/VehicleAssignmentService";
import {useSelector} from "react-redux";
import toast from "react-hot-toast";

const AssignVehicle = () => {
    const admin = useSelector((state) => state.auth);
    const [vehicle, setVehicle] = useState(null);
    const [driver, setDriver] = useState(null);
    const [company, setCompany] = useState(null);
    const [chain, setChain] = useState(null);
    const [outlet, setOutlet] = useState(null);
    const [shift, setShift] = useState(null);
    const [chainOptions, setChainOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [outletOptions, setOutletOptions] = useState([]);
    const [driverOptions, setDriverOptions] = useState([]);
    const [vehicleOptions, setVehicleOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [shiftOptions] = useState([
        {label: 'Morning Shift', value: 'Morning Shift'},
        {label: 'Evening Shift', value: 'Evening Shift'},
        {label: 'Full Shift', value: 'Full Shift'},
        {label: 'Morning Break Shift', value: 'Morning Break Shift'},
        {label: 'Evening Break Shift', value: 'Evening Break Shift'},
    ]);
    const [allowedPermissions, setAllowedPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        delete: false
    });

    useEffect(() => {
        let add = false, edit = false, view = false, deletePermission = false;
        admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/vehicle-assignments')).map((permission) => {
            add = add || permission.add;
            edit = edit || permission.edit;
            view = view || permission.view;
            deletePermission = deletePermission || permission.delete;
        });
        setAllowedPermissions({
            add,
            edit,
            view,
            deletePermission
        });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!allowedPermissions.add) {
            toast.success('You are not allowed to perform this action');
            return;
        }
        setLoading(true);
        // console.log(chain);
        const data = {
            driver: driver.value,
            vehicle: vehicle.value,
            company: company.value,
            shift: shift.value,
            ...(chain && {chain: chain.value}),
            ...(outlet && {outlet: outlet.value}),
        };
        const res = await VehicleAssignmentService.assign(data);
        if (res) {
            e.target.reset();
            setVehicle(null);
            setDriver(null);
            setChain(null);
            setOutlet(null);
            setShift(null);
        }
        setLoading(false);
    };

    const getCompanies = async () => {
        const allItems = await CompanyService.companies();
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.companies.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].name, value: allItems[i]._id});
            }
        }
        setCompanyOptions(items);
    }

    const getChains = async () => {
        const allItems = await ChainService.chains([company.value]);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.chains.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].address, value: allItems[i]._id});
            }
        }
        setChainOptions(items);
    }

    const getOutlets = async () => {
        const allItems = await OutletService.outlets([chain.value]);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            if (admin.outlets.find((item) => item._id === allItems[i]._id) || (admin.permissions.find((item) => item.title === 'Super Admin'))) {
                items.push({label: allItems[i].location, value: allItems[i]._id});
            }
        }
        setOutletOptions(items);
    }

    const getDrivers = async () => {
        const data = {
            companies: [company.value],
            ...(chain && {chains: [chain.value]}),
            ...(outlet && {outlets: [outlet.value]}),
        };
        const allItems = await EmployeeService.employees(data);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            items.push({label: allItems[i].name, value: allItems[i]._id});
        }
        setDriverOptions(items);
    }

    const getVehicles = async () => {
        const data = {
            companies: [company.value],
            ...(chain && {chains: [chain.value]}),
            ...(outlet && {outlets: [outlet.value]}),
        };
        const allItems = await VehicleService.vehicles(data);
        const items = [];
        for (let i = 0; i < allItems.length; i++) {
            items.push({label: allItems[i].number, value: allItems[i]._id});
        }
        setVehicleOptions(items);
    }

    useEffect(() => {
        getCompanies();
    }, []);

    useEffect(() => {
        if (company !== null) {
            getChains();
        }
    }, [company])

    useEffect(() => {
        if (chain !== null) {
            getOutlets();
        }
    }, [chain])

    useEffect(() => {
        if (company !== null) {
            getVehicles();
            getDrivers();
        }
    }, [chain, company, outlet])


    return (
        <>
            <div className="card mb-5">
                <div className="card-header p-3">
                    <h5 className="card-title">Assign Vehicle</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="type" className="form-label">Company <span
                                    className='text-danger'>(required)</span></label>
                                <Select
                                    name="chain"
                                    options={companyOptions}
                                    value={company}
                                    onChange={setCompany} required={true}/>
                            </div>

                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="type" className="form-label">Chain</label>
                                <Select
                                    name="chain"
                                    options={chainOptions}
                                    value={chain}
                                    onChange={setChain}/>
                            </div>

                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="type" className="form-label">Outlets</label>
                                <Select
                                    name="chain"
                                    options={outletOptions}
                                    value={outlet}
                                    onChange={setOutlet}/>
                            </div>

                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="type" className="form-label">Vehicle <span
                                    className='text-danger'>(required)</span></label>
                                <Select
                                    name="chain"
                                    options={vehicleOptions}
                                    value={vehicle}
                                    onChange={setVehicle} required={true}/>
                            </div>

                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="type" className="form-label">Driver <span
                                    className='text-danger'>(required)</span></label>
                                <Select
                                    name="chain"
                                    options={driverOptions}
                                    value={driver}
                                    onChange={setDriver} required={true}/>
                            </div>

                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="type" className="form-label">Shift <span
                                    className='text-danger'>(required)</span></label>
                                <Select
                                    name="chain"
                                    options={shiftOptions}
                                    value={shift}
                                    onChange={setShift} required={true}/>
                            </div>

                        </div>

                        <div className={'d-flex'}>
                            <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                            </button>
                            <button type="button" className="btn btn-secondary me-2" disabled={loading} onClick={() => {
                                window.history.back();
                            }}>{loading &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"/>} Back
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </>
    );
}

export default AssignVehicle;
