import "./App.css";
import {Link, Route, Routes} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Toaster} from "react-hot-toast";
import Base from "./pages/Base";
import store from "./store";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import {AuthService} from "./services/AuthService";
import AuthMiddleware from "./middleware/AuthMiddleware";
import EditProfile from "./pages/auth/EditProfile";
import AddCompany from "./pages/company/AddCompany";
import ManageCompanies from "./pages/company/ManageCompanies";
import EditCompany from "./pages/company/EditCompany";
import EditChain from "./pages/chain/EditChain";
import EditOutlet from "./pages/outlet/EditOutlet";
import AddVehicle from "./pages/vehicle/AddVehicle";
import ManageVehicles from "./pages/vehicle/ManageVehicles";
import EditVehicle from "./pages/vehicle/EditVehicle";
import AddEmployee from "./pages/employee/AddEmployee";
import ManageEmployees from "./pages/employee/ManageEmployees";
import EditEmployee from "./pages/employee/EditEmployee";
import AssignVehicle from "./pages/vehicle-assignment/AssignVehicle";
import ManageVehicleAssignments from "./pages/vehicle-assignment/ManageVehicleAssignments";
import EditAssignedVehicle from "./pages/vehicle-assignment/EditAssignedVehicle";
import AddExpenseCategory from "./pages/expense-category/AddExpenseCategory";
import ManageExpenseCategories from "./pages/expense-category/ManageExpenseCategories";
import EditExpenseCategory from "./pages/expense-category/EditExpenseCategory";
import AddExpenseType from "./pages/expense-type/AddExpenseType";
import ManageExpenseTypes from "./pages/expense-type/ManageExpenseTypes";
import EditExpenseType from "./pages/expense-type/EditExpenseType";
import ManageExpenses from "./pages/expense/ManageExpenses";
import ExpenseDetails from "./pages/expense/ExpenseDetails";
import ManageDriverReports from "./pages/report/ManageDriverReports";
import ManageVehicleReports from "./pages/report/ManageVehicleReports";
import AddPenalty from "./pages/penalty/AddPenalty";
import ManagePenalties from "./pages/penalty/ManagePenalties";
import EditPenalty from "./pages/penalty/EditPenalty";
import AddPermission from "./pages/Permission/AddPermission";
import ManagePermissions from "./pages/Permission/ManagePermissions";
import EditPermission from "./pages/Permission/EditPermission";
import ManagePenaltyReports from "./pages/report/ManagePenaltyReports";
import AddPenaltyType from "./pages/penalty-type/AddPenaltyType";
import ManagePenaltyTypes from "./pages/penalty-type/ManagePenaltyTypes";
import EditPenaltyType from "./pages/penalty-type/EditPenaltyType";
import RequestAccountDeletion from "./pages/delete-account/RequestAccountDeletion";
import ManageExpenseHistory from "./pages/expense/ManageExpenseHistory";
import ManagePenaltyHistory from "./pages/penalty/ManagePenaltyHistory";

function App() {
    const admin = useSelector((state) => state.auth);
    const authToken = useSelector((state) => state.auth.token);
    const [allowedRoutes, setAllowedRoutes] = useState([]);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (authToken != null && !initialized) {

            setInitialized(true);
        }
    }, [authToken]);

    useEffect(() => {
        store.dispatch(AuthService.refreshToken());
    }, []);


    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={
                        <AuthMiddleware>
                            <Dashboard/>
                        </AuthMiddleware>
                    }
                />
                <Route
                    path="/auth/login"
                    element={
                        <Login/>
                    }
                />
                <Route
                    path="/request-account-deletion"
                    element={
                        <RequestAccountDeletion/>
                    }
                />
                <Route
                    path="/auth/edit-profile"
                    element={
                        <AuthMiddleware>
                            <EditProfile/>
                        </AuthMiddleware>
                    }
                />
                {
                    admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/companies')).length > 0 && <>
                        <Route
                            path="/companies/add"
                            element={
                                <AuthMiddleware>
                                    <AddCompany/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/companies"
                            element={
                                <AuthMiddleware>
                                    <ManageCompanies/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/companies/edit/:id"
                            element={
                                <AuthMiddleware>
                                    <EditCompany/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/chains/edit/:id"
                            element={
                                <AuthMiddleware>
                                    <EditChain/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/outlets/:company/edit/:id"
                            element={
                                <AuthMiddleware>
                                    <EditOutlet/>
                                </AuthMiddleware>
                            }
                        />
                    </>
                }
                {
                    admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/vehicles')).length > 0 && <>
                        <Route
                            path="/vehicles/add"
                            element={
                                <AuthMiddleware>
                                    <AddVehicle/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/vehicles"
                            element={
                                <AuthMiddleware>
                                    <ManageVehicles/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/vehicles/edit/:id"
                            element={
                                <AuthMiddleware>
                                    <EditVehicle/>
                                </AuthMiddleware>
                            }
                        />
                    </>
                }
                {
                    admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/employees')).length > 0 && <>
                        <Route
                            path="/employees/add"
                            element={
                                <AuthMiddleware>
                                    <AddEmployee/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/employees"
                            element={
                                <AuthMiddleware>
                                    <ManageEmployees/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/employees/edit/:id"
                            element={
                                <AuthMiddleware>
                                    <EditEmployee/>
                                </AuthMiddleware>
                            }
                        />
                    </>
                }
                {
                    admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/vehicle-assignments')).length > 0 && <>
                        <Route
                            path="/vehicle-assignments/add"
                            element={
                                <AuthMiddleware>
                                    <AssignVehicle/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/vehicle-assignments"
                            element={
                                <AuthMiddleware>
                                    <ManageVehicleAssignments/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/vehicle-assignments/edit/:id"
                            element={
                                <AuthMiddleware>
                                    <EditAssignedVehicle/>
                                </AuthMiddleware>
                            }
                        />
                    </>
                }
                {
                    admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/expense-categories')).length > 0 && <>
                        <Route
                            path="/expense-categories/add"
                            element={
                                <AuthMiddleware>
                                    <AddExpenseCategory/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/expense-categories"
                            element={
                                <AuthMiddleware>
                                    <ManageExpenseCategories/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/expense-categories/edit/:id"
                            element={
                                <AuthMiddleware>
                                    <EditExpenseCategory/>
                                </AuthMiddleware>
                            }
                        />
                    </>
                }
                {
                    admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/expense-types')).length > 0 && <>
                        <Route
                            path="/expense-types/add"
                            element={
                                <AuthMiddleware>
                                    <AddExpenseType/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/expense-types"
                            element={
                                <AuthMiddleware>
                                    <ManageExpenseTypes/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/expense-types/edit/:id"
                            element={
                                <AuthMiddleware>
                                    <EditExpenseType/>
                                </AuthMiddleware>
                            }
                        />

                    </>
                }
                {
                    admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/penalty-types')).length > 0 && <>
                        <Route
                            path="/penalty-types/add"
                            element={
                                <AuthMiddleware>
                                    <AddPenaltyType/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/penalty-types"
                            element={
                                <AuthMiddleware>
                                    <ManagePenaltyTypes/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/penalty-types/edit/:id"
                            element={
                                <AuthMiddleware>
                                    <EditPenaltyType/>
                                </AuthMiddleware>
                            }
                        />

                    </>
                }
                {
                    admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/expenses')).length > 0 && <>
                        <Route
                            path="/expenses"
                            element={
                                <AuthMiddleware>
                                    <ManageExpenses/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/expenses/edit/:id"
                            element={
                                <AuthMiddleware>
                                    <ExpenseDetails/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/expenses/history/:id"
                            element={
                                <AuthMiddleware>
                                    <ManageExpenseHistory/>
                                </AuthMiddleware>
                            }
                        />

                    </>
                }
                {
                    admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/reports') || permission.routes.includes('/reports/by-driver') || permission.routes.includes('/reports/by-vehicle') || permission.routes.includes('/reports/penalty')).length > 0 && <>
                        <Route
                            path="/reports/by-driver"
                            element={
                                <AuthMiddleware>
                                    <ManageDriverReports/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/reports/by-vehicle"
                            element={
                                <AuthMiddleware>
                                    <ManageVehicleReports/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/reports/penalty"
                            element={
                                <AuthMiddleware>
                                    <ManagePenaltyReports />
                                </AuthMiddleware>
                            }
                        />
                    </>
                }
                {
                    admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/penalties')).length > 0 && <>
                        <Route
                            path="/penalties/add"
                            element={
                                <AuthMiddleware>
                                    <AddPenalty/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/penalties"
                            element={
                                <AuthMiddleware>
                                    <ManagePenalties/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/penalties/edit/:id"
                            element={
                                <AuthMiddleware>
                                    <EditPenalty/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/penalties/history/:id"
                            element={
                                <AuthMiddleware>
                                    <ManagePenaltyHistory/>
                                </AuthMiddleware>
                            }
                        />

                    </>
                }
                {
                    admin.permissions.filter((permission) => permission.routes.includes('/') || permission.routes.includes('/permissions')).length > 0 && <>
                        <Route
                            path="/permissions/add"
                            element={
                                <AuthMiddleware>
                                    <AddPermission/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/permissions"
                            element={
                                <AuthMiddleware>
                                    <ManagePermissions/>
                                </AuthMiddleware>
                            }
                        />
                        <Route
                            path="/permissions/edit/:id"
                            element={
                                <AuthMiddleware>
                                    <EditPermission/>
                                </AuthMiddleware>
                            }
                        />

                    </>
                }
            </Routes>
            <Toaster/>
        </>
    );
}

export default App;
